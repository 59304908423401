<template>
  <div class="table__month">
    <h3 class="historical-ref-heading">Historical Reference - Past Two Seasons</h3>
    <article v-for="previousSeason in previousSeasonsData" :key="previousSeason.season">
      <h5 class="bottom-border-sellin mb-0" @click="toggleCollapse(previousSeason.year, 'expand-table')">
        <span>{{ previousSeason.season }}</span
        >Retailer Sell in and Sell Thru<span class="arrow" :class="{ expanded: visible }"></span>
      </h5>
      <table class="table my-1" :id="['my-1-' + previousSeason.year]">
        <thead>
          <tr class="border-top-none text-center">
            <th class="labels_th text-left">Sell In</th>
            <th class="months_th" v-for="month in months" :key="month.value">{{ month.shortName }}</th>
            <th>Total</th>
            <th class="bg-lightblue">{{ previousSeason.season }} SELLIN%</th>
          </tr>
        </thead>
        <tbody>
          <tr class="sub-rows">
            <td class="text-left">
              <tr class="font-bold">
                TOTAL UNITS
              </tr>
              <tr class="font-bold">
                TOTAL WHSL
              </tr>
            </td>

            <td v-for="month in months" :key="month.value">
              <tr>
                {{
                  (getSum(previousSeason.data, { month: month.value }, "units") +
                    getSum(previousSeason.repeatUnits, { month: month.value }, "units"))
                    | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  (getSum(previousSeason.data, { month: month.value }, "gross") +
                    getSum(previousSeason.repeatUnits, { month: month.value }, "gross"))
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>
            <td>
              <tr>
                {{
                  (getBookingSum(previousSeason.data, null, "units") +
                    getBookingSum(previousSeason.repeatUnits, null, "units"))
                    | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  (getBookingSum(previousSeason.data, null, "gross") +
                    getBookingSum(previousSeason.repeatUnits, null, "gross"))
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>

            <td class="bg-lightblue">
              <tr></tr>
              <tr></tr>
            </td>
          </tr>

          <tr class="sub-rows">
            <td class="text-left">
              <tr class="font-bold">
                BOOKING UNITS
              </tr>
              <tr class="font-bold">
                BOOKING WHSL
              </tr>
            </td>

            <td v-for="month in months" :key="month.value">
              <tr>
                {{
                  getSum(previousSeason.data, { month: month.value }, "units") | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  getSum(previousSeason.data, { month: month.value }, "gross") | divideByThousand | currencyFormat
                }}
              </tr>
            </td>

            <td>
              <tr>
                {{
                  getBookingSum(previousSeason.data, null, "units") | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  getBookingSum(previousSeason.data, null, "gross") | divideByThousand | currencyFormat
                }}
              </tr>
            </td>

            <td class="bg-lightblue">
              <tr>
                {{
                  (getSum(previousSeason.data, null, "units") /
                    (getBookingSum(previousSeason.data, null, "units") +
                      getBookingSum(previousSeason.repeatUnits, null, "units")))
                    | percent
                }}
              </tr>
              <tr>
                {{
                  (getSum(previousSeason.data, null, "gross") /
                    (getBookingSum(previousSeason.data, null, "gross") +
                      getBookingSum(previousSeason.repeatUnits, null, "gross")))
                    | percent
                }}
              </tr>
            </td>
          </tr>

          <tr class="sub-rows">
            <td class="text-left">
              <tr class="font-bold">
                REPEAT UNITS
              </tr>
              <tr class="font-bold">
                REPEAT WHSL
              </tr>
            </td>

            <td v-for="month in months" :key="month.value">
              <tr>
                {{
                  getSum(previousSeason.repeatUnits, { month: month.value }, "units") | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  getSum(previousSeason.repeatUnits, { month: month.value }, "gross")
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>

            <td>
              <tr>
                {{
                  getBookingSum(previousSeason.repeatUnits, null, "units") | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  getBookingSum(previousSeason.repeatUnits, null, "gross") | divideByThousand | currencyFormat
                }}
              </tr>
            </td>

            <td class="bg-lightblue">
              <tr>
                {{
                  (getBookingSum(previousSeason.repeatUnits, null, "units") /
                    (getBookingSum(previousSeason.data, null, "units") +
                      getBookingSum(previousSeason.repeatUnits, null, "units")))
                    | percent
                }}
              </tr>
              <tr>
                {{
                  (getBookingSum(previousSeason.repeatUnits, null, "gross") /
                    (getBookingSum(previousSeason.data, null, "gross") +
                      getBookingSum(previousSeason.repeatUnits, null, "gross")))
                    | percent
                }}
              </tr>
            </td>
          </tr>

          <tr class="sub-rows">
            <td class="text-left">
              <tr class="font-bold">
                Launch units
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  segmentType
                }}
              </tr>
              <tr class="font-bold">
                TL WHSL
              </tr>
            </td>

            <td v-for="month in months" :key="month.value">
              <tr>
                {{
                  (getSum(previousSeason.data, { month: month.value, type: "launch" }, "units") +
                    getSum(previousSeason.repeatUnits, { month: month.value, type: "launch" }, "units"))
                    | commaSeparator
                }}
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  (getSum(
                    previousSeason.data,
                    {
                      month: month.value,
                      type: "launch",
                      segment: segmentType,
                    },
                    "units"
                  ) +
                    getSum(
                      previousSeason.repeatUnits,
                      {
                        month: month.value,
                        type: "launch",
                        segment: segmentType,
                      },
                      "units"
                    ))
                    | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  (getSum(previousSeason.data, { month: month.value, type: "launch" }, "gross") +
                    getSum(previousSeason.repeatUnits, { month: month.value, type: "launch" }, "gross"))
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>

            <td>
              <tr>
                {{
                  (getBookingSum(previousSeason.data, { type: "launch" }, "units") +
                    getBookingSum(previousSeason.repeatUnits, { type: "launch" }, "units"))
                    | commaSeparator
                }}
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  (getBookingSum(
                    previousSeason.data,
                    {
                      type: "launch",
                      segment: segmentType,
                    },
                    "units"
                  ) +
                    getBookingSum(
                      previousSeason.repeatUnits,
                      {
                        type: "launch",
                        segment: segmentType,
                      },
                      "units"
                    ))
                    | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  (getBookingSum(previousSeason.data, { type: "launch" }, "gross") +
                    getBookingSum(previousSeason.repeatUnits, { type: "launch" }, "gross"))
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>

            <td class="bg-lightblue">
              <tr>
                {{
                  ((getBookingSum(previousSeason.data, { type: "launch" }, "units") +
                    getBookingSum(previousSeason.repeatUnits, { type: "launch" }, "units")) /
                    (getBookingSum(previousSeason.data, null, "units") +
                      getBookingSum(previousSeason.repeatUnits, null, "units")))
                    | percent
                }}
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  ((getBookingSum(previousSeason.data, { type: "launch", segment: segmentType }, "units") +
                    getBookingSum(previousSeason.repeatUnits, { type: "launch", segment: segmentType }, "units")) /
                    (getBookingSum(previousSeason.data, { type: "launch" }, "units") +
                      getBookingSum(previousSeason.repeatUnits, { type: "launch" }, "units")))
                    | percent
                }}
              </tr>
              <tr>
                {{
                  ((getBookingSum(previousSeason.data, { type: "launch" }, "gross") +
                    getBookingSum(previousSeason.repeatUnits, { type: "launch" }, "gross")) /
                    (getBookingSum(previousSeason.data, null, "gross") +
                      getBookingSum(previousSeason.repeatUnits, null, "gross")))
                    | percent
                }}
              </tr>
            </td>
          </tr>

          <tr class="sub-rows">
            <td class="text-left">
              <tr class="font-bold">
                Migrating units
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  segmentType
                }}
              </tr>
              <tr class="font-bold">
                TL WHSL
              </tr>
            </td>

            <td v-for="month in months" :key="month.value">
              <tr>
                {{
                  (getSum(previousSeason.data, { month: month.value, type: "migrating" }, "units") +
                    getSum(previousSeason.repeatUnits, { month: month.value, type: "migrating" }, "units"))
                    | commaSeparator
                }}
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  (getSum(
                    previousSeason.data,
                    {
                      month: month.value,
                      type: "migrating",
                      segment: segmentType,
                    },
                    "units"
                  ) +
                    getSum(
                      previousSeason.repeatUnits,
                      {
                        month: month.value,
                        type: "migrating",
                        segment: segmentType,
                      },
                      "units"
                    ))
                    | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  (getSum(previousSeason.data, { month: month.value, type: "migrating" }, "gross") +
                    getSum(previousSeason.repeatUnits, { month: month.value, type: "migrating" }, "gross"))
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>

            <td>
              <tr>
                {{
                  (getBookingSum(previousSeason.data, { type: "migrating" }, "units") +
                    getBookingSum(previousSeason.repeatUnits, { type: "migrating" }, "units"))
                    | commaSeparator
                }}
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  (getBookingSum(
                    previousSeason.data,
                    {
                      type: "migrating",
                      segment: segmentType,
                    },
                    "units"
                  ) +
                    getBookingSum(
                      previousSeason.repeatUnits,
                      {
                        type: "migrating",
                        segment: segmentType,
                      },
                      "units"
                    ))
                    | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  (getBookingSum(previousSeason.data, { type: "migrating" }, "gross") +
                    getBookingSum(previousSeason.repeatUnits, { type: "migrating" }, "gross"))
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>

            <td class="bg-lightblue">
              <tr>
                {{
                  ((getBookingSum(previousSeason.data, { type: "migrating" }, "units") +
                    getBookingSum(previousSeason.repeatUnits, { type: "migrating" }, "units")) /
                    (getBookingSum(previousSeason.data, null, "units") +
                      getBookingSum(previousSeason.repeatUnits, null, "units")))
                    | percent
                }}
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  ((getBookingSum(previousSeason.data, { type: "migrating", segment: segmentType }, "units") +
                    getBookingSum(previousSeason.repeatUnits, { type: "migrating", segment: segmentType }, "units")) /
                    (getBookingSum(previousSeason.data, { type: "migrating" }, "units") +
                      getBookingSum(previousSeason.repeatUnits, { type: "migrating" }, "units")))
                    | percent
                }}
              </tr>
              <tr>
                {{
                  ((getBookingSum(previousSeason.data, { type: "migrating" }, "gross") +
                    getBookingSum(previousSeason.repeatUnits, { type: "migrating" }, "gross")) /
                    (getBookingSum(previousSeason.data, null, "gross") +
                      getBookingSum(previousSeason.repeatUnits, null, "gross")))
                    | percent
                }}
              </tr>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table my-2" :id="['my-2-' + previousSeason.year]">
        <thead>
          <tr class="border-top-none text-center">
            <th class="labels_th text-left">Sell Thru Units</th>
            <th class="months_th" v-for="month in months" :key="month.value">{{ month.shortName }}</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr class="sub-rows">
            <th scope="row">
              <tr class="font-bold">
                Total
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  segmentType
                }}
              </tr>
            </th>

            <td v-for="month in months" :key="month.value">
              <tr>
                <span v-if="month.value < 4">
                  {{
                    getSum(prevSeasonSellThruUnits, { year: previousSeason.year + 1, month: month.value }, "units")
                      | commaSeparator
                  }}
                </span>
                <span v-else>
                  {{
                    getSum(prevSeasonSellThruUnits, { year: previousSeason.year, month: month.value }, "units")
                      | commaSeparator
                  }}
                </span>
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                <span v-if="month.value < 4">
                  {{
                    getData(prevSeasonSellThruUnits, {
                      year: previousSeason.year + 1,
                      month: month.value,
                      segment: segmentType,
                    }).units | commaSeparator
                  }}
                </span>
                <span v-else>
                  {{
                    getData(prevSeasonSellThruUnits, {
                      year: previousSeason.year,
                      month: month.value,
                      segment: segmentType,
                    }).units | commaSeparator
                  }}
                </span>
              </tr>
            </td>

            <td>
              <tr>
                {{
                  (getBookingSum(prevSeasonSellThruUnits, { year: previousSeason.year }, "units") +
                    (getBookingSum(prevSeasonSellThruUnits, { year: previousSeason.year + 1, month: 1 }, "units") +
                      getBookingSum(prevSeasonSellThruUnits, { year: previousSeason.year + 1, month: 2 }, "units") +
                      getBookingSum(prevSeasonSellThruUnits, { year: previousSeason.year + 1, month: 3 }, "units")) -
                    (getBookingSum(prevSeasonSellThruUnits, { year: previousSeason.year, month: 1 }, "units") +
                      getBookingSum(prevSeasonSellThruUnits, { year: previousSeason.year, month: 2 }, "units") +
                      getBookingSum(prevSeasonSellThruUnits, { year: previousSeason.year, month: 3 }, "units")))
                    | commaSeparator
                }}
              </tr>
              <tr v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  (getBookingSum(
                    prevSeasonSellThruUnits,
                    { year: previousSeason.year, segment: segmentType },
                    "units"
                  ) +
                    (getBookingSum(
                      prevSeasonSellThruUnits,
                      {
                        year: previousSeason.year + 1,
                        month: 1,
                        segment: segmentType,
                      },
                      "units"
                    ) +
                      getBookingSum(
                        prevSeasonSellThruUnits,
                        {
                          year: previousSeason.year + 1,
                          month: 2,
                          segment: segmentType,
                        },
                        "units"
                      ) +
                      getBookingSum(
                        prevSeasonSellThruUnits,
                        {
                          year: previousSeason.year + 1,
                          month: 3,
                          segment: segmentType,
                        },
                        "units"
                      )) -
                    (getBookingSum(
                      prevSeasonSellThruUnits,
                      {
                        year: previousSeason.year,
                        month: 1,
                        segment: segmentType,
                      },
                      "units"
                    ) +
                      getBookingSum(
                        prevSeasonSellThruUnits,
                        {
                          year: previousSeason.year,
                          month: 2,
                          segment: segmentType,
                        },
                        "units"
                      ) +
                      getBookingSum(
                        prevSeasonSellThruUnits,
                        {
                          year: previousSeason.year,
                          month: 3,
                          segment: segmentType,
                        },
                        "units"
                      )))
                    | commaSeparator
                }}
              </tr>
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { filtersMixin } from "@/mixins/filters";
import { API } from "aws-amplify";
import { getSellThruUnits, getCategoryIdList } from "@/graphql/queries";
export default {
  mixins: [filtersMixin],
  computed: {
    ...mapGetters(["months", "retailer", "product", "season", "previousSeasonsData"]),
    startDate() {
      return `${this.season.year - 2}-04-01`;
    },
    endDate() {
      return `${this.season.year}-03-31`;
    },
    hasRollup() {
      return this.season.months.length < 12;
    },
    filterSegments() {
      const segments = this.product.segments;
      if (typeof segments !== "undefined" && segments !== null && segments.length > 0) {
        return segments;
      }
      return this.segments;
    },
  },
  data() {
    return {
      visible: false,
      prevSeasonSellThruUnits: [],
      repeatUnitSummary: [],
      segments: ["Elite", "Performance", "Recreational", "All"],
    };
  },
  mounted() {
    this.getPrevSellThruUnits();
    const thisInstance = this;
    this.$root.$on("updatedConfig", function () {
      //   thisInstance.getRepeatUnits();
      thisInstance.getPrevSellThruUnits();
    });
  },
  methods: {
    toggleCollapse(id, className) {
      document.getElementById("my-1-" + id).classList.toggle(className);
      document.getElementById("my-2-" + id).classList.toggle(className);
      this.visible = !this.visible;
    },
    getBookingSum(data, filterObj, key) {
      const range = this.season.monthsRange;
      function inRange(obj) {
        return obj.month >= range.min && obj.month <= range.max;
      }
      return this.getSum(data.filter(inRange), filterObj, key);
    },
    getBookingSumHoliday(season_year, segment) {
      let units = 0;
      units += this.getSum(this.prevSeasonSellThruUnits, { year: season_year, month: 12, segment: segment }, "units");
      units += this.getSum(
        this.prevSeasonSellThruUnits,
        { year: season_year + 1, month: 1, segment: segment },
        "units"
      );
      units += this.getSum(
        this.prevSeasonSellThruUnits,
        { year: season_year + 1, month: 2, segment: segment },
        "units"
      );
      units += this.getSum(
        this.prevSeasonSellThruUnits,
        { year: season_year + 1, month: 3, segment: segment },
        "units"
      );
      return units;
    },
    getBookingSumHolidayTotal(season_year, segments) {
      let units = 0;
      segments.forEach((segment) => {
        units += this.getSum(this.prevSeasonSellThruUnits, { year: season_year, month: 12, segment: segment }, "units");
        units += this.getSum(
          this.prevSeasonSellThruUnits,
          { year: season_year + 1, month: 1, segment: segment },
          "units"
        );
        units += this.getSum(
          this.prevSeasonSellThruUnits,
          { year: season_year + 1, month: 2, segment: segment },
          "units"
        );
        units += this.getSum(
          this.prevSeasonSellThruUnits,
          { year: season_year + 1, month: 3, segment: segment },
          "units"
        );
      });
      return units;
    },

    async getPrevSellThruUnits() {
      this.prevSeasonSellThruUnits = [];
      let begin_season_filter_str = "%BTH%";
      if (this.product.level1_bth_or_holiday_id.toLowerCase() == "holiday") {
        begin_season_filter_str = "%Holiday%";
      }

      const { data } = await API.graphql({
        query: getCategoryIdList,
        variables: {
          category_id: this.product.category_id,
        },
      });
      const categoryIds = data.getCategoryIdList[0].ids;

      const response = await API.graphql({
        query: getSellThruUnits,
        variables: {
          soldto_id: this.retailer.soldto_id,
          category: categoryIds,
          start_date: this.startDate,
          end_date: this.endDate,
          begin_season_filter_str: begin_season_filter_str,
          top_planning_input_id: this.product.top_planning_inputs_id,
        },
      });
      this.prevSeasonSellThruUnits = response.data.getSellThruUnits;
    },
  },
};
</script>

<style scoped lang="scss">
$header_column_width: 160px;
$input_column_width: 75px;

.labels_th {
  min-width: $header_column_width;
  width: $header_column_width;
}

.months_th {
  min-width: $input_column_width;
  width: $input_column_width;
}

.table td,
.table th {
  padding: 0.75rem 0.25rem;
}
</style>
