<template>
    <div class="selectorPadding">
        <div class="floatGroup">
            <p class="mx-0 px-0"><b>Select Material Allocation Method</b>
                <Tooltip
                    text="The allocation methods below will split the quantities entered above at the material level based on the selected method using historical sell-in or sell-through data. Hover over the material description (far left) to see the historical reference material">
                </Tooltip>
            </p>
            <p v-if="splitSelectedValue">Last saved material selection: <span>{{ split_mapping[splitSelectedValue] }}</span>
            </p>
            <div class="aselect m-0" :data-value="value" :data-list="options.value">
                <div class="selector" @click="toggleDropdown($event)" @blur="hideWarning($event)" tabindex="0">
                    <div class="label">
                        <span>{{ value }}</span>
                    </div>
                    <div class="arrow" :class="{ expanded: dropdownVisible }"></div>
                    <div :class="{ hidden: !dropdownVisible }">
                        <ul>
                            <li :class="{ current: item.value === value }" v-for="item in options" :key="item.value"
                                @click="selectOption(item)">
                                {{ item.description }}
                                <Tooltip :text="item.tooltip"></Tooltip>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tooltip from '@/components/common/Tooltip';
export default {
    components: {
        Tooltip
    },
    data() {
        console.log(this.retailer);
        return {
            dropdownVisible: false,
            value: 'BTH Bookings',
            options: [
                { description: 'BTH Bookings', value: 111, startDate: '2021-04-01', endDate: '2021-11-31', tooltip: 'Use this option if you do not provide sell thru data' },
                { description: `Sell Thru BTH (Retailer)`, value: 112, startDate: '2021-04-01', endDate: '2022-03-31', tooltip: 'Use this option if you have been providing consistent sell thru data on at least a monthly basis (Suggested for larger retailers, use Bauer UPCs, sharing for at least 3 years)' },
                { description: 'Sell Thru BTH (Country)', value: 113, startDate: '2021-04-01', endDate: '2022-03-31', tooltip: 'Use this option if you do not provide or have been providing inconsistent sell thru data.  This gives a broader range of sell thru which may not be pertinent to your region of the country.  (more recommended for ROW retailers)' },
                { description: 'Sell Thru BTH (Region)', value: 114, startDate: '2021-04-01', endDate: '2022-03-31', tooltip: 'Use this option if you do not provide or have been providing inconsistent sell thru data.  This gives a narrower range of sell thru which may be more pertinent to your business. ' },
                { description: 'Sell Thru BTH (Market)', value: 115, startDate: '2021-04-01', endDate: '2022-03-31', tooltip: 'More recommended for ROW retailers not N America' },
            ],
            split_mapping: {
                111: 'BTH Bookings',
                112: 'Sell Thru BTH (Retailer)',
                113: 'Sell Thru BTH (Country)',
                114: 'Sell Thru BTH (Region)',
                115: 'Sell Thru BTH (Market)',
            },
        };
    },
    computed: {
        ...mapGetters([
            'retailer',
            'splitSelectedValue',
            'options'
        ]),
    },
    mounted() {
        const option1 = this.options.find(x => x.value == 112);
        const option2 = this.options.find(x => x.value == 113);
        const option3 = this.options.find(x => x.value == 114);
        const option4 = this.options.find(x => x.value == 115);
        option1.description += ` - ${this.retailer.soldto_id}`;
        option2.description += ` - ${this.retailer.sorg}`;
        option3.description += ` - ${this.retailer.region}`;
        option4.description += ` - ${this.retailer.market}`;
    },
    methods: {
        toggleDropdown(event) {
            this.dropdownVisible = !this.dropdownVisible;
            if (this.dropdownVisible) {
                this.$warning.show(event, "WARNING: Changing allocation method will reset any custom values.", 100);
            }
            else {
                this.$warning.hide();
            }

        },

        hideWarning(event) {
            if (this.dropdownVisible) {
                this.toggleDropdown(event);
            }

        },

        selectOption(option) {
            this.value = option.description;
            this.$events.emit('selectMaterialAllocation', option);
            this.$warning.hide();
        },

    },
};
</script>
<style scoped lang="scss">
p {
    margin-top: -1em;
    padding-left: 5px !important;

    span {
        font-weight: 400;
    }
}

.selectorPadding {
    padding: 0px !important;
}

.floatGroup {
    float: right;
}

.aselect {
    margin-top: -1em !important;
    min-width: 25em;
}</style>