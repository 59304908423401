<template>
  <div class="table_summary">
    <h5>Material Level - Open And Planned Orders</h5>
    <div class="row">
      <div class="col-4">
        <table class="table">
          <thead>
            <tr>
              <th>FAMILY</th>
              <th>UNITS</th>
              <th>WHSL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="family in this.families()" :key="family">
              <td>
                <tr class="heading">
                  {{
                    family
                  }}
                </tr>
                <tr>
                  Launch
                </tr>
                <tr>
                  Migrating
                </tr>
              </td>

              <td>
                <tr class="heading">
                  {{
                    (getFamilyUnitsForYearByType("launch", family) + getFamilyUnitsForYearByType("migrating", family)) | commaSeparator
                  }}
                </tr>
                <tr>
                  {{
                    getFamilyUnitsForYearByType("launch", family) | commaSeparator
                  }}
                </tr>
                <tr>
                  {{
                    getFamilyUnitsForYearByType("migrating", family) | commaSeparator
                  }}
                </tr>
              </td>

              <td>
                <tr class="heading">
                  {{
                    (getFamilyPriceForYearByType("launch", family) + getFamilyPriceForYearByType("migrating", family))
                      | roundDecimals
                      | divideByThousand
                      | currencyFormat
                  }}
                </tr>
                <tr>
                  {{
                    getFamilyPriceForYearByType("launch", family) | roundDecimals | divideByThousand | currencyFormat
                  }}
                </tr>
                <tr>
                  {{
                    getFamilyPriceForYearByType("migrating", family) | roundDecimals | divideByThousand | currencyFormat
                  }}
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-4">
        <table class="table">
          <thead>
            <tr>
              <th>SEGMENT / PGS</th>
              <th>UNITS</th>
              <th>WHSL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="segment in this.segments()" :key="segment">
              <td>
                <tr class="heading">
                  {{
                    segment
                  }}
                </tr>
                <tr>
                  Launch
                </tr>
                <tr>
                  Migrating
                </tr>
              </td>

              <td>
                <tr class="heading">
                  {{
                    (getSegmentUnitsForYearByType("launch", segment) + getSegmentUnitsForYearByType("migrating", segment)) | commaSeparator
                  }}
                </tr>
                <tr>
                  {{
                    getSegmentUnitsForYearByType("launch", segment) | commaSeparator
                  }}
                </tr>
                <tr>
                  {{
                    getSegmentUnitsForYearByType("migrating", segment)  | commaSeparator
                  }}
                </tr>
              </td>

              <td>
                <tr class="heading">
                  {{
                    (getSegmentPriceForYearByType("launch", segment) +
                      getSegmentPriceForYearByType("migrating", segment))
                      | roundDecimals
                      | divideByThousand
                      | currencyFormat
                  }}
                </tr>
                <tr>
                  {{
                    getSegmentPriceForYearByType("launch", segment) | roundDecimals | divideByThousand | currencyFormat
                  }}
                </tr>
                <tr>
                  {{
                    getSegmentPriceForYearByType("migrating", segment)
                      | roundDecimals
                      | divideByThousand
                      | currencyFormat
                  }}
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-4">
        <table class="table">
          <thead>
            <tr>
              <th>GENDER</th>
              <th>UNITS</th>
              <th>WHSL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="gender in this.genders()" :key="gender">
              <td>
                <tr class="heading">
                  {{
                    gender
                  }}
                </tr>
                <tr>
                  Launch
                </tr>
                <tr>
                  Migrating
                </tr>
              </td>

              <td>
                <tr class="heading">
                  {{
                    (getGenderUnitsForYearByType("launch", gender) + getGenderUnitsForYearByType("migrating", gender))  | commaSeparator
                  }}
                </tr>
                <tr>
                  {{
                    getGenderUnitsForYearByType("launch", gender) | commaSeparator
                  }}
                </tr>
                <tr>
                  {{
                    getGenderUnitsForYearByType("migrating", gender) | commaSeparator
                  }}
                </tr>
              </td>

              <td>
                <tr class="heading">
                  {{
                    (getGenderPriceForYearByType("launch", gender) + getGenderPriceForYearByType("migrating", gender))
                      | roundDecimals
                      | divideByThousand
                      | currencyFormat
                  }}
                </tr>
                <tr>
                  {{
                    getGenderPriceForYearByType("launch", gender) | roundDecimals | divideByThousand | currencyFormat
                  }}
                </tr>
                <tr>
                  {{
                    getGenderPriceForYearByType("migrating", gender) | roundDecimals | divideByThousand | currencyFormat
                  }}
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { filtersMixin } from "@/mixins/filters";

export default {
  mixins: [filtersMixin],
  computed: {
    ...mapGetters(["actuals", "season"]),

    getFamilyUnitsForYearByType() {
      return (type, family) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val =
          this.$materialAllocation.plannedUnitsByYearForAllFamilyOccurencesByType(type, family) +
          this.getSum(this.actuals, { launch_type: type, family: family }, "units_sold");
        return val;
      };
    },
    getFamilyPriceForYearByType() {
      return (type, family) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val =
          this.$materialAllocation.plannedPriceByYearForAllFamilyOccurencesByType(type, family) +
          this.getSum(this.actuals, { launch_type: type, family: family }, "amount");
        return val;
      };
    },
    getSegmentUnitsForYearByType() {
      return (type, segment) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val =
          this.$materialAllocation.plannedUnitsByYearForSegment(type, segment) +
          this.getSum(this.actuals, { launch_type: type, segment: segment }, "units_sold");
        return val;
      };
    },
    getSegmentPriceForYearByType() {
      return (type, segment) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val =
          this.$materialAllocation.plannedPriceByYearForSegment(type, segment) +
          this.getSum(this.actuals, { launch_type: type, segment: segment }, "amount");
        return val;
      };
    },
    getGenderUnitsForYearByType() {
      return (type, gender) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val =
          this.$materialAllocation.plannedUnitsByYearForAllGenderOccurencesByType(type, gender) +
          this.getSum(this.actuals, { launch_type: type, gender: gender }, "units_sold");
        return val;
      };
    },
    getGenderPriceForYearByType() {
      return (type, gender) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val =
          this.$materialAllocation.plannedPriceByYearForAllGenderOccurencesByType(type, gender) +
          this.getSum(this.actuals, { launch_type: type, gender: gender }, "amount");
        return val;
      };
    },

    segments() {
      return () => {
        return this.$materialAllocation.uniqueSegments.map((item) => item.segment);
      };
    },
    families() {
      return () => {
        return this.$materialAllocation.uniqueFamilies;
      };
    },
    genders() {
      return () => {
        return this.$materialAllocation.uniqueGenders.map((item) => item.gender);
      };
    },
  },
};
</script>
