<template>
  <div class="planning__body">
    <Loading v-if="loading" class="py-5" text="Fetching data - please wait..." :key="loadingKey" />

    <template v-else>
      <h6 class="header-note">
        Note : Wholesale transactions are denoted in the local currency and are typically displayed in thousands.
      </h6>
      <div class="planning__body-block flex-box flex-column top-spacing" v-show="true">
        <Growth v-show="true" />
        <MonthlyBooking v-show="true" :key="monthlyBookingKey" />
        <PreviousSeasonsSellin v-show="true" :key="previousSeasonsSellinKey" />
      </div>

      <h4>
        Material Level Allocation
        <Tooltip :text="materialAllocationToolTip"></Tooltip>
      </h4>

      <div class="planning__body-block flex-box flex-column" v-show="true">
        <MaterialAllocationSelector :key="materialAllocationSelectorKey" />
        <MaterialAllocation :key="materialAllocationKey" />
      </div>

      <h4>Categorical Summary</h4>
      <div class="planning__body-block flex-box flex-column">
        <CategoricalSummary v-show="true" :key="categoricalSummaryKey" />
      </div>
    </template>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { mapGetters } from "vuex";
import Tooltip from "@/components/common/Tooltip";
import Loading from "@/components/common/Loading";
import MaterialAllocationSelector from "@/components/planning/suggested/MaterialAllocationSelector";
import Growth from "@/components/planning/suggested/Growth";
import MonthlyBooking from "@/components/planning/suggested/MonthlyBooking";
import PreviousSeasonsSellin from "@/components/planning/suggested/PreviousSeasonsSellin";
import MaterialAllocation from "@/components/planning/suggested/MaterialAllocation";
import CategoricalSummary from "@/components/planning/suggested/CategoricalSummary";
import { fetchUpc } from "@/graphql/queries";
import { sortBy, groupBy } from "lodash";

import {
  getSuggestedDataByMonth,
  getActuals,
  openOrdersData,
  getHistoricGrowth,
  getRepeatUnits,
} from "@/graphql/queries";

export default {
  components: {
    Loading,
    MaterialAllocationSelector,
    Growth,
    MonthlyBooking,
    MaterialAllocation,
    PreviousSeasonsSellin,
    CategoricalSummary,
    Tooltip,
  },
  data() {
    return {
      loading: true,
      showSuggestedMonthly: false,
      loadingKey: 0,
      materialAllocationSelectorKey: 0,
      growthKey: 0,
      monthlyBookingKey: 0,
      previousSeasonsSellinKey: 0,
      materialAllocationKey: 0,
      categoricalSummaryKey: 0,
      hasSavedMonths: false,
      materialAllocationToolTip: `Below shows Open Orders under "O" and planned orders under "P" at the material level. You can adjust and input into the planned cells`,
      groups: {
        Order: null,
        SellThru: 112,
        Region: 114,
        Country: 113,
        Market: 115,
      },
    };
  },
  computed: {
    ...mapGetters([
      "months",
      "retailer",
      "product",
      "season",
      "shipTo",
      "isShipToSelected",
      "suggestedData",
      "suggestedBookings",
      "previousSeasonsData",
      "growthMultiplier",
      "planningConfig",
      "currentSeasonYear",
      "sellThruData",
      "countryData",
      "marketData",
      "regionData",
      "firstDimension",
      "secondDimension",
    ]),
    // TODO CHECK WHETHER THIS IS EVER NOT THE CASE
    currentSeason() {
      return this.season.year - 1;
    },
    startDate() {
      return `${this.season.year - 2}-04-01`;
    },
    endDate() {
      return `${this.season.year}-03-31`;
    },
  },
  mounted() {
    this.$events.on("changeSettings", () => {
      this.$store.state.allocationRemainders = {};
      this.$store.state.earliestMonthsCanPlan = [];
      this.$store.state.latestMonthsCanPlan = [];
    });
    this.getSuggestedDataByMonth();
    this.getActuals();
    this.openOrdersData();
    this.getHistoricGrowth();
    this.$events.on("updateSuggestedBookings", (booking) => {
      this.updateSuggestedBookings(booking);
    });
    this.$events.on("updatedConfig", () => {
      this.parseSuggestedData();
    });
    //Move api call to suggested page
    this.$store.commit("setBookingData", []);
    this.$store.commit("resetFetchUpcStores");
    this.$store.commit("setTableUpcLoader", true);
    this.getGraphData();
    const thisInstance = this;
    this.$root.$on("updatedConfig", function () {
      this.$store.commit("resetSuggestedBooking");
      thisInstance.getSuggestedDataByMonth();
      thisInstance.getActuals();
      thisInstance.openOrdersData();
      thisInstance.getHistoricGrowth();
      thisInstance.$store.commit("setBookingData", []);
    });
  },
  beforeDestroy() {
    this.$events.off("changeSettings");
    this.$events.off("updateSuggestedBookings");
    this.$events.off("updatedConfig");
    this.$root.$off("updatedConfig");
  },

  methods: {
    async getSuggestedDataByMonth() {
      this.$overlay.show("Retrieving suggested data for previous season");
      let data = [];
      const response = await API.graphql({
        query: getSuggestedDataByMonth,
        variables: {
          soldto_id: this.retailer.soldto_id,
          category: this.product.id,
          start_date: `${this.season.year - 2}-04-01`,
          end_date: `${this.season.year}-03-31`,
          current_year: this.season.year - 1,
          previous_year: this.season.year - 2,
          next_year: this.season.year,
        },
      });
      data = response.data.getSuggestedDataByMonth;
      if (!data.length) {
        this.$overlay.show("No suggested data for previous season was found");
        setTimeout(this.$overlay.hide, 2000);
      } else {
        this.$store.commit("setSuggestedData", data);
      }
      this.parseSuggestedData();
      await this.getRepeatUnits();
      this.$overlay.hide();
      this.loading = false;
    },
    parseSuggestedData() {
      // RESET previousSeasonsData[{}] TO PREVENT HOTRELOAD ISSUES
      this.$store.commit("resetStores");

      this.previousSeasonsData[0].year = this.season.year - 1;
      this.previousSeasonsData[1].year = this.season.year - 2;
      this.previousSeasonsData[0].season = "S'" + this.previousSeasonsData[0].year.toString().substring(2);
      this.previousSeasonsData[1].season = "S'" + this.previousSeasonsData[1].year.toString().substring(2);

      // NOTE THAT THIS DATA IS SET IN USERDETAILS AND DOES NOT APPEAR TO BE USED ANYWHERE ELSE
      this.suggestedData.forEach((bookingData) => {
        let booking = {
          month: bookingData.month,
          units: bookingData.units,
          gross: bookingData.gross,
          segment: bookingData.segment,
          type: bookingData.type.replace("_previous", "").replace("_current", ""),
        };
        const ind =
          (bookingData.year == this.season.year && booking.month < 4) ||
          (bookingData.year == this.season.year - 1 && booking.month > 3)
            ? 0
            : 1;
        this.updateSeasonData(ind, booking);
      });
    },
    updateSeasonData(index, booking) {
      this.previousSeasonsData[index].data.push(booking);
      this.previousSeasonsData[index].grossTotal += booking.gross;
      this.previousSeasonsData[index].unitsTotal += booking.units;
    },
    updateSuggestedBookings(booking) {
      if (this.hasSavedMonths) {
        return;
      }
      let suggestedBooking = { ...booking };
      this.$store.commit("setSuggestedBooking", suggestedBooking);
      this.$events.emit("suggestedBookingsUpdated", booking);
    },
    async getRepeatUnits() {
      const response = await API.graphql({
        query: getRepeatUnits,
        variables: {
          soldto_id: this.retailer.soldto_id,
          category: this.product.id,
          start_date: `${this.season.year - 2}-04-01`,
          end_date: `${this.season.year}-03-31`,
          current_year: this.season.year - 1,
          previous_year: this.season.year - 2,
          next_year: this.season.year,
        },
      });
      this.parseRepeatData(response.data.getRepeatUnits);
    },
    parseRepeatData(repeatData) {
      repeatData.forEach((item) => {
        item.type = item.type.replace("_previous", "").replace("_current", "");
        const ind =
          (item.year == this.season.year && item.month < 4) || (item.year == this.season.year - 1 && item.month > 3)
            ? 0
            : 1;
        this.updateRepeatData(ind, item);
      });
    },
    updateRepeatData(index, item) {
      this.previousSeasonsData[index].repeatUnits.push(item);
      this.previousSeasonsData[index].grossTotal += item.gross;
      this.previousSeasonsData[index].unitsTotal += item.units;
    },

    async getData(filter) {
      let begin_season_filter_str = "%- BTH";
      if (this.product.level1_bth_or_holiday_id === "holiday") {
        begin_season_filter_str = "%- Holiday";
      }

      const params = {
        soldto_id: this.retailer.soldto_id,
        category: this.product.id,
        category_value: this.product.category_value,
        start_month: 0,
        end_month: 0,
        planning_year: this.currentSeasonYear,
        sorg: this.retailer.sorg,
        currency_type: "PR00",
        smu_group_sec: this.retailer.SEC,
        smu_group_sdc: this.retailer.SDC,
        market: this.retailer.market,
        filter: filter,
        market_filter: this.retailer.marketQuery,
        region: this.retailer.region,
        start_date: this.startDate,
        end_date: this.endDate,
        begin_season_filter_str: begin_season_filter_str,
        top_planning_input_id: this.product.top_planning_inputs_id.toLowerCase(),
        category_id: this.product.category_id,
        ship_to_id: this.retailer.shipto_id,
      };

        //fetchUPC does not require a startDate and endDate and other parameters as well : TODO
      let response = await API.graphql(graphqlOperation(fetchUpc, params));
      response = response.data.fetchUpc;
      response = sortBy(response, (product) => this.firstDimension[product.dim1]);
      const groupedBydim1 = groupBy(response, "dim1");
      let sortedResult = [];
      Object.keys(groupedBydim1).forEach((dim1Key) => {
        groupedBydim1[dim1Key] = sortBy(groupedBydim1[dim1Key], (product) => this.secondDimension[product.dim2]);
        sortedResult = sortedResult.concat(groupedBydim1[dim1Key]);
      });
      response = sortedResult;
      // store values in a session to retrieve later if needed
      switch (filter) {
        case 112:
          this.$store.commit("setSellThruData", response);
          break;
        case 113:
          this.$store.commit("setCountryData", response);
          break;
        case 114:
          this.$store.commit("setRegionData", response);
          break;
        case 115:
          if (this.$materialAllocation.areMaterialsInitialized && !this.$materialAllocation.areUPCsInitialized) {
            this.$materialAllocation.initializeUpcs(response);
            response.forEach((variant) => {
              this.$materialAllocation.updateUpcWeight(variant.material, variant.upc, variant.weight, true);
            });
            this.$store.commit("setMarketData", response);
          }
          break;
      }
      return response;
    },
    async getGraphData() {
      for (const v of Object.values(this.groups)) {
        if (!v) continue;
        if (this.getDataForFilter(v) && this.getDataForFilter(v).length) {
          return;
        }
        await this.getData(v);
      }
      this.$store.commit("setTableUpcLoader", false);
    },
    getDataForFilter(v) {
      switch (v) {
        case 112:
          return this.sellThruData;
        case 113:
          return this.countryData;
        case 114:
          return this.regionData;
        case 115:
          return this.marketData;
        default:
          return [];
      }
    },
    async getActuals() {
      const response = await API.graphql({
        query: getActuals,
        variables: {
          category: this.product.id,
          selling_year: this.season.year,
          soldto_id: this.retailer.soldto_id,
          sorg: this.retailer.sorg,
          currency_type: "PR00",
        },
      });
      const data = response.data.getActuals;
      this.$store.commit("setActuals", data);
    },
    async openOrdersData() {
      const response = await API.graphql({
        query: openOrdersData,
        variables: {
          soldto_id: this.retailer.soldto_id,
          category: this.product.id,
          season_year: this.currentSeasonYear,
        },
      });
      const data = response.data.openOrdersData;
      this.$store.commit("setOpenOrders", data);
    },
    async getHistoricGrowth() {
      const response = await API.graphql({
        query: getHistoricGrowth,
        variables: {
          soldto_id: this.retailer.soldto_id,
          category: this.product.id,
        },
      });
      const data = response.data.getHistoricGrowth;
      this.$store.commit("setHistoricGrowth", data[0].gross);
    },
    canPlanForMonth(monthValue, type, segment) {
      let month = this.months.find((x) => x.value == monthValue);
      let foundEarliest = this.$store.state.earliestMonthsCanPlan.find((x) => x.type == type && x.segment == segment);
      if (!foundEarliest || month.value < foundEarliest.month) {
        if (monthValue == 1 || monthValue == 2 || monthValue == 3) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
