<template>
    <div class="table__month">
        <h5 class="mt-0"><span>{{ currentSeason }} ({{ currentSeasonMonthAndYearRange }})</span>Growth over {{
            previousSeason }} ({{ previousSeasonMonthAndYearRange }})</h5>
        <div class="row overflow">
            <div class="col-auto">
                <div class="widget_growth widget_legend">
                    <h4 class="invisible">-</h4>
                    <div class="row">
                        <div class="col invisible">-</div>
                    </div>
                    <div class="row" v-for="segmentType in filterSegments()" :key="segmentType">
                        <div class="col">{{ segmentType }}</div>
                    </div>
                    <div class="row font-weight-bold">
                        <div class="col">Total</div>
                    </div>
                </div>
            </div>
            <div class="col pl-1 overflow">
                <div class="widget_growth">
                    <h4>{{ currentSeason }} Total Bookings</h4>
                    <div class="row border-bottom">
                        <div class="col-3">Units</div>
                        <div class="col-3">Units G%</div>
                        <div class="col-3">Wholesale</div>
                        <div class="col-3">Wholesale %</div>
                    </div>
                    <div class="row border-bottom" v-for="segmentType in filterSegments()" :key="segmentType">
                        <div class="col-3">
                            {{(getSegmentUnitsForYear(segmentType) + getSum(actuals, {  segment: segmentType }, 'units_sold')) | commaSeparator}}
                        </div>

                        <div class="col-3">
                            {{
                                (((getSegmentUnitsForYear(segmentType) + getSum(actuals, {
                                     segment: segmentType
                                }, 'units_sold') - getSum(LastSeasonsData, { segment: segmentType }, 'units'))) /
                                    (getSum(LastSeasonsData, { segment: segmentType }, 'units'))) |percent
                            }}
                        </div>

                        <div class="col-3">
                            {{
                                (getSegmentPriceForYear(segmentType) + getSum(actuals, {
                                     segment: segmentType
                                }, 'amount'))
                                | isValidNumber
                                | roundDecimals
                                | divideByThousand
                                | currencyFormat
                            }}
                        </div>

                        <div class="col-3">
                           
                            {{
                                ((getSegmentPriceForYear(segmentType) + getSum(actuals, {
                                     segment: segmentType
                                }, 'amount') - getSum(LastSeasonsData, { segment: segmentType }, 'sum_whole_price_net')) /
                                    (getSum(LastSeasonsData, { segment: segmentType }, 'sum_whole_price_net')))
                                | percent
                            }}
                        </div>
                    </div>
                    <div class="row font-weight-bold">
                      <div class="col-3">

                        {{(getUnitsForYear() + getSum(actuals, {}, 'units_sold'))| commaSeparator }}</div>
                        <div class="col-3">{{ ((getUnitsForYear() + getSum(actuals, {
                           
                        }, 'units_sold') - getSum(LastSeasonsData, null, 'units')) /
                            (getSum(LastSeasonsData, null, 'units'))) |
                            percent }}</div>
                        <div class="col-3">{{ (getPriceForYear() + getSum(actuals, {
                          
                        }, 'amount')) | isValidNumber | roundDecimals | divideByThousand | currencyFormat }}</div>
                        <div class="col-3">{{ ((getPriceForYear() + getSum(actuals, {
                            
                        }, 'amount') - getSum(LastSeasonsData, null, 'sum_whole_price_net')) /
                            (getSum(LastSeasonsData, null, 'sum_whole_price_net'))) | percent
                        }}</div>
                    </div>

                    <div class="row mt-1">
                        <div class="col">
                            <div class="arrow-up" v-bind:class="{
                                        down: ((getUnitsForYear() + getSum(actuals, {
                                         
                                        }, 'units_sold') - getSum(LastSeasonsData, null, 'units')) /
                                            (getSum(LastSeasonsData, null, 'units'))) < 0
                                    }"></div>
                        </div>
                        <div class="col">
                            <div class="arrow-up" v-bind:class="{
                                down: ((getPriceForYear() + getSum(actuals, {
                               
                                }, 'amount') - getSum(LastSeasonsData, null, 'sum_whole_price_net')) /
                                    (getSum(LastSeasonsData, null, 'sum_whole_price_net'))) < 0
                            }"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col overflow">
                <div class="widget_growth">
                    <h4>{{ currentSeason }} Launch Bookings</h4>
                    <div class="row border-bottom">
                        <div class="col-3">Units</div>
                        <div class="col-3">Units G%</div>
                        <div class="col-3">Wholesale</div>
                        <div class="col-3">Wholesale %</div>
                    </div>
                    <div class="row border-bottom" v-for="segmentType in filterSegments()" :key="segmentType">
                        <div class="col-3">
                            {{
                                (getSegmentUnitsForYearByType('launch', segmentType) +
                                getSum(actuals, {  launch_type: 'launch', segment: segmentType },
                                    'units_sold')) | commaSeparator
                            }}
                        </div>

                        <div class="col-3">
                            {{
                                ((getSegmentUnitsForYearByType('launch', segmentType) +
                                    getSum(actuals, {  launch_type: 'launch', segment: segmentType },
                                        'units_sold') - getSum(LastSeasonsData, { product_type: 'launch', segment: segmentType },
                                            'units')) /
                                    (getSum(LastSeasonsData, { product_type: 'launch', segment: segmentType },
                                        'units'))) | percent
                            }}
                        </div>

                        <div class="col-3">
                            {{
                                (getSegmentPriceForYearByType('launch', segmentType) +
                                    getSum(actuals, {  launch_type: 'launch', segment: segmentType },
                                        'amount'))
                                | isValidNumber
                                | roundDecimals
                                | divideByThousand
                                | currencyFormat
                            }}
                        </div>

                        <div class="col-3">
                            {{
                                ((getSegmentPriceForYearByType('launch', segmentType) +
                                    getSum(actuals, {  launch_type: 'launch', segment: segmentType },
                                        'amount') - getSum(LastSeasonsData, { product_type: 'launch', segment: segmentType },
                                            'sum_whole_price_net')) /
                                    (getSum(LastSeasonsData, { product_type: 'launch', segment: segmentType },
                                        'sum_whole_price_net')))
                                | percent
                            }}
                        </div>

                    </div>
                    <div class="row font-weight-bold">
                        <div class="col-3">
                            {{ (getUnitsForYearByType('launch') + getSum(actuals, {
                                launch_type: 'launch'
                            }, 'units_sold')) | commaSeparator }}
                        </div>

                        <div class="col-3">
                            {{
                                ((getUnitsForYearByType('launch') + getSum(actuals, {
                                    launch_type: 'launch'
                                }, 'units_sold') - getSum(LastSeasonsData, { product_type: 'launch' },
                                    'units')) /
                                    getSum(LastSeasonsData, { product_type: 'launch' },
                                        'units'))
                                | percent
                            }}
                        </div>

                        <div class="col-3">
                            {{
                                (getPriceForYearByType('launch') + getSum(actuals, {
                                   launch_type: 'launch'
                                }, 'amount'))
                                | isValidNumber
                                | roundDecimals
                                | divideByThousand
                                | currencyFormat
                            }}
                        </div>

                        <div class="col-3">
                            {{
                                ((getPriceForYearByType('launch') + getSum(actuals, {
                                    launch_type:  'launch'
                                }, 'amount') - getSum(LastSeasonsData, { product_type: 'launch' },
                                    'sum_whole_price_net')) /
                                    getSum(LastSeasonsData, { product_type: 'launch' },
                                        'sum_whole_price_net'))
                                | percent
                            }}
                        </div>

                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <div class="arrow-up" v-bind:class="{
                                        down: ((getUnitsForYearByType('launch') + getSum(actuals, {
                                             launch_type: 'launch'
                                        }, 'units_sold') - getSum(LastSeasonsData, { product_type: 'launch' },
                                            'units')) /
                                            getSum(LastSeasonsData, { product_type: 'launch' },
                                                'units')) < 0
                                    }"></div>
                        </div>
                        <div class="col">
                            <div class="arrow-up" v-bind:class="{
                                down: ((getPriceForYearByType('launch') + getSum(actuals, {
                                   launch_type: 'launch'
                                }, 'amount') - getSum(LastSeasonsData, { product_type: 'launch' },
                                    'sum_whole_price_net')) /
                                    getSum(LastSeasonsData, { product_type: 'launch' },
                                        'sum_whole_price_net')) < 0
                            }"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col overflow">
                <div class="widget_growth">
                    <h4>{{ currentSeason }} Migrating Bookings</h4>
                    <div class="row border-bottom">
                        <div class="col-3">Units</div>
                        <div class="col-3">Units G%</div>
                        <div class="col-3">Wholesale</div>
                        <div class="col-3">Wholesale %</div>
                    </div>
                    <div class="row border-bottom" v-for="segmentType in filterSegments()" :key="segmentType">
                        <div class="col-3">
                            {{
                                (getSegmentUnitsForYearByType('migrating', segmentType) +
                                getSum(actuals, {
                                     launch_type: 'migrating', segment: segmentType
                                },
                                    'units_sold'))  | commaSeparator
                            }}
                        </div>

                        <div class="col-3">
      {{
                                ((getSegmentUnitsForYearByType('migrating', segmentType) +
                                    getSum(actuals, {
                                      launch_type: 'migrating', segment: segmentType
                                    },
                                        'units_sold') - getSum(LastSeasonsData, { product_type: 'migrating', segment: segmentType },
                                            'units')) /
                                    (getSum(LastSeasonsData, { product_type: 'migrating', segment: segmentType },
                                        'units'))) | percent
                            }}
                        </div>

                        <div class="col-3">
                            {{
                                (getSegmentPriceForYearByType('migrating', segmentType) +
                                    getSum(actuals, {
                                         launch_type: 'migrating', segment: segmentType
                                    },
                                        'amount'))
                                | isValidNumber
                                | roundDecimals
                                | divideByThousand
                                | currencyFormat
                            }}
                        </div>

                        <div class="col-3">
                          {{
                                ((getSegmentPriceForYearByType('migrating', segmentType) +
                                    getSum(actuals, {
                                         launch_type: 'migrating', segment: segmentType
                                    },
                                        'amount') - getSum(LastSeasonsData, { product_type: 'migrating', segment: segmentType },
                                            'sum_whole_price_net')) /
                                    (getSum(LastSeasonsData, { product_type: 'migrating', segment: segmentType },
                                        'sum_whole_price_net')))
                                | percent
                            }}
                        </div>

                    </div>
                    <div class="row font-weight-bold">
                        <div class="col-3">
                            {{ (getUnitsForYearByType('migrating') + getSum(actuals, {
                                launch_type: 'migrating'
                            }, 'units_sold'))  | commaSeparator }}
                        </div>

                        <div class="col-3">
                           {{
                                ((getUnitsForYearByType('migrating') + getSum(actuals, {
                                    launch_type: 'migrating'
                                }, 'units_sold') - getSum(LastSeasonsData, { product_type: 'migrating' },
                                    'units')) /
                                    getSum(LastSeasonsData, { product_type: 'migrating' },
                                        'units'))
                                | percent
                            }}
                        </div>

                        <div class="col-3">
                            {{
                                (getPriceForYearByType('migrating') + getSum(actuals, {
                                    launch_type: 'migrating'
                                }, 'amount'))
                                | isValidNumber
                                | roundDecimals
                                | divideByThousand
                                | currencyFormat
                            }}
                        </div>

                        <div class="col-3">
                           {{
                                ((getPriceForYearByType('migrating') + getSum(actuals, {
                                     launch_type: 'migrating'
                                }, 'amount') - getSum(LastSeasonsData, { product_type: 'migrating' },
                                    'sum_whole_price_net')) /
                                    getSum(LastSeasonsData, { product_type: 'migrating' },
                                        'sum_whole_price_net'))
                                | percent
                            }}
                        </div>

                    </div>
                    <div class="row mt-1">
                        <div class="col">
                        <div class="arrow-up" v-bind:class="{
                                        down: ((getUnitsForYearByType('migrating') + getSum(actuals, {
                                             launch_type: 'migrating'
                                        }, 'units_sold') - getSum(LastSeasonsData, { product_type: 'migrating' },
                                            'units')) /
                                            getSum(LastSeasonsData, { product_type: 'migrating' },
                                                'units')) < 0
                                    }"></div>
                        </div>
                        <div class="col">
                            <div class="arrow-up" v-bind:class="{
                                down: ((getPriceForYearByType('migrating') + getSum(actuals, {
                                     launch_type: 'migrating'
                                }, 'amount') - getSum(LastSeasonsData, { product_type: 'migrating' },
                                    'sum_whole_price_net')) /
                                    getSum(LastSeasonsData, { product_type: 'migrating' },
                                        'sum_whole_price_net')) < 0
                            }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from 'aws-amplify';
import { filtersMixin } from '@/mixins/filters';
import {fetchLastYearBookings} from '@/graphql/queries';

export default {
    mixins: [filtersMixin],
    data() {
        return {
            segments: ['Elite', 'Performance', 'Recreational', 'All'],
            suggestedBTHBooking: [],
            localMonthsIndex: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
            LastSeasonsData: null
        };
    },
    computed: {
        ...mapGetters([
            'actuals',
            'historicGrowth',
            'season',
            'suggestedData',
            'previousSeasonsData',
            'suggestedBookings',
            'product',
            'months',
            'currentSeason',
            'previousSeason',
            'earliestMonthCanPlanGlobal',
            'latestMonthCanPlan',
            'retailer',
            'currentSeasonMonthAndYearRange',
            'previousSeasonMonthAndYearRange',
            'bookingYear',
            'planningConfig'
        ]),


        filterSegments() {
            return () => {
            const val = this.$materialAllocation.uniqueSegments.map(item => item.segment);
            return val;
            }
        },

        retailerCategoryValue() {
            return this.product.category_value; // Use the mapped getter in the computed property
        },

        getSegmentUnitsForYearByType() {
            return (type, segment) => {
                this.recompute = this.$materialAllocation.changeEvent;
                const val = this.$materialAllocation.plannedUnitsByYearForSegment(type, segment);
                return val;
            };
        },

        getSegmentPriceForYearByType() {
            return (type, segment) => {
                this.recompute = this.$materialAllocation.changeEvent;
                const val = this.$materialAllocation.plannedPriceByYearForSegment(type, segment);
                return val;
            };
        },

        getSegmentUnitsForYear() {
            return (segment) => {
                this.recompute = this.$materialAllocation.changeEvent;
                return this.getSegmentUnitsForYearByType('launch', segment) + this.getSegmentUnitsForYearByType('migrating', segment);
            };
        },

        getSegmentPriceForYear() {
            return (segment) => {
                this.recompute = this.$materialAllocation.changeEvent;
                return this.getSegmentPriceForYearByType('launch', segment) + this.getSegmentPriceForYearByType('migrating', segment);
            };
        },

        getUnitsForYearByType() {
            return (type) => {
                this.recompute = this.$materialAllocation.changeEvent;
                return this.$materialAllocation.plannedUnitsByYearForOrdertype(type);
            };
        },

        getPriceForYearByType() {
            return (type) => {
                this.recompute = this.$materialAllocation.changeEvent;
                return this.$materialAllocation.plannedPriceByYearForOrdertype(type);
            };
        },

        getUnitsForYear() {
            return () => {
                this.recompute = this.$materialAllocation.changeEvent;
                return this.$materialAllocation.plannedUnitsByYearForAllOrdertypes();
            };
        },
        getPriceForYear() {
            return () => {
                this.recompute = this.$materialAllocation.changeEvent;
                return this.$materialAllocation.plannedPriceByYearForAllOrdertypes();
            };
        },

    },
    mounted() {
        this.suggestedBookingsBTH();
        const thisInstance = this;
        this.$root.$on('updatedConfig', function () {
            thisInstance.suggestedBookingsBTH();
        });
    },
    beforeDestroy() {
        this.$events.off('updatedConfig');
    },
    watch: {
        retailerCategoryValue: {
            immediate: true,
            handler(newValue) {
                this.fetchLastSeasonData(newValue);
            },
        }
    },
    methods: {
        async fetchLastSeasonData(categoryValue) {
            try{
                    const reqDelDateStart = (this.bookingYear - 1).toString() + '-03' + '-31' ;
                    const reqDelDateEnd = (this.bookingYear).toString() + '-04' + '-01' ;
                    const season = this.planningConfig.product.value.split("-")[0];
                    const response = await API.graphql({
                            query: fetchLastYearBookings,
                            variables: {
                                'soldto_id': this.planningConfig.retailer.soldto_id,
                                'category': categoryValue,
                                'req_delivery_dte_start' : reqDelDateStart ,
                                'req_delivery_dte_end' : reqDelDateEnd ,
                                'previousYear' : (this.bookingYear - 1),
                                'season' : season
                            },
                    });
                this.LastSeasonsData = response.data.fetchLastYearBookings;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        removeEquipmentSuffix(inputString) {
            if (inputString.endsWith(' Equipment')) {
                return inputString.replace(' Equipment', '');
            }
            return inputString;
        },
        suggestedBookingsBTH() {
            const bthMonths = [4, 5, 6, 7, 8, 9, 10, 11];
            this.suggestedBTHBooking = [];
            this.suggestedBookings.forEach(element => {
                if (bthMonths.indexOf(element.month) >= 0) {
                    this.suggestedBTHBooking.push(element);
                }
            });
        },

        canPlanForMonthIndex(index) {
            if (index < this.earliestMonthCanPlanGlobal) {
                return false;
            } else {
                return true;
            }
        },
        canPlanForMonth(month) {
            if (month.value < this.earliestMonthCanPlanGlobal) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>
