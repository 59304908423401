<template>
  <div class="table__month">
    <h5 class="top-border my-0">
      <span>{{ currentSeason }}</span
      >Monthly Booking Sell In Flow <small class="text-muted">(Launch &amp; Migrating)</small>
      <Tooltip
        :text="`If units appear in the planned (P) cells below, they either represent quantities booked last year as a starting point for planning this year or last saved data from a prior planning session`"
      >
      </Tooltip>
    </h5>
    <p class="my-1">
      The "O" below represents Open Orders already processed by Bauer. The "P" below represents Planning which is where
      you can input units (eventually become open orders once submitted and processed by Bauer).
    </p>
    <table class="table my-0 table__scroll-horizontal">
      <thead>
        <tr class="border-top-none text-center">
          <th class="labels_th"></th>
          <th
            class="months_th"
            v-for="month in months"
            :key="month.shortName"
            :colspan="canPlanComputed(month) ? 2 : 1"
          >
            {{ month.shortName }}
          </th>
          <th colspan="2" class="total-col">Total</th>
          <th>{{ currentSeason }}</th>
          <th style="max-width: 5em">Stock Units</th>
          <th>Open Orders</th>
        </tr>
        <tr class="border-top-none text-center">
          <th class="labels_th"></th>
          <template v-for="month in months">
            <th
              v-if="month != null"
              :key="month.shortName + '-open'"
              style="border-left: 1px solid #dadada; border-right: 1px solid #dadada"
            >
              O
            </th>
            <th
              v-if="canPlanComputed(month)"
              :key="month.shortName + '-plan'"
              style="border-left: 1px solid #dadada; border-right: 1px solid #dadada"
            >
              P
            </th>
          </template>
          <template>
            <th style="border-left: 1px solid #dadada; border-right: 1px solid #dadada">O</th>
            <th style="border-left: 1px solid #dadada; border-right: 1px solid #dadada">P</th>
          </template>
          <th style="font-size: small; font-weight: normal">Units by %</th>
          <th style="font-size: small; font-weight: normal">{{ stockUnitsAsOf }}</th>
          <th style="font-size: small; font-weight: normal">thru {{ currentSeasonYear }}-03-31</th>
        </tr>
      </thead>
      <tbody>
        <tr class="sub-rows">
          <td scope="row">
            <p class="font-bold p-row">BOOKING UNITS</p>
            <p class="font-bold p-row">BOOKING WHSL</p>
          </td>

          <td
            v-for="month in months"
            :key="month.shortName"
            :colspan="canPlanComputed(month) ? 2 : 1"
            style="border-left: 1px solid #dadada; border-right: 1px solid #dadada"
            class="table-col"
          >
            <template v-if="canPlanComputed(month)">
              <div class="twoCol addRightBorder">
                {{ getSum(actuals, { selling_month: month.value }, "units_sold") | commaSeparator }}
              </div>
              <div class="twoCol">
                {{ getAllUnitsByMonth(month.value) | commaSeparator }}
              </div>
              <div class="twoCol addRightBorder">
                {{
                  getSum(actuals, { selling_month: month.value }, "amount")
                    | isValidNumber
                    | roundDecimals
                    | divideByThousand
                    | currencyFormat
                }}
              </div>
              <div class="twoCol">
                {{
                  getAllPriceByMonth(month.value) | isValidNumber | roundDecimals | divideByThousand | currencyFormat
                }}
              </div>
            </template>
            <template v-else>
              <div>
                {{ filterOpenOrdersByMonth(month) }}
              </div>
              <div>
                {{
                  getSum(actuals, { selling_month: month.value }, "amount")
                    | isValidNumber
                    | roundDecimals
                    | divideByThousand
                    | currencyFormat
                }}
              </div>
            </template>
          </td>
          <td colspan="2" style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <div class="twoCol addRightBorder">
              <div>
                {{ getSum(actuals, null, "units_sold") | commaSeparator }}
              </div>
            </div>
            <div class="twoCol">
              <div>
                {{ getAllUnitsByYear() }}
              </div>
            </div>
            <div class="twoCol addRightBorder">
              <div>
                {{
                  getSum(actuals, null, "amount") | isValidNumber | roundDecimals | divideByThousand | currencyFormat
                }}
              </div>
            </div>
            <div class="twoCol">
              <div>{{ getAllPriceByYear() | isValidNumber | roundDecimals | divideByThousand | currencyFormat }}</div>
            </div>
          </td>

          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <div>-</div>
            <div>-</div>
          </td>
          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <div>
              {{ getSum(stockUnits, null, "retailer_stock_units") | commaSeparator }}
            </div>
            <div>-</div>
          </td>

          <td class="table-col">
            <div>
              {{ getAsOfUnitTotalsAll(season.year) | commaSeparator }}
            </div>
            <div>-</div>
          </td>
        </tr>
        <tr class="sub-rows">
          <th scope="row">
            <div class="segment-div inline-block-div">
              <p class="font-bold p-row">Launch</p>
            </div>
            <div
              v-for="segmentType in filterSegments"
              :key="segmentType"
              :set="(isSegmentOffered = checkIfSegmentOffered(segmentType, 'launch'))"
              class="segment-div"
            >
              <p
                :class="{ 'segment-not-offered': isSegmentOffered }"
                class="p-row text-ellipsis"
                v-tooltip.right="segmentType.length > 18 ? segmentType : ''"
              >
                {{ segmentType }}
              </p>
            </div>
            <p class="font-bold p-row segment-div inline-block-div">TL WHSL</p>
          </th>

          <td
            v-for="month in months"
            :key="month.shortName"
            :colspan="canPlanComputed(month) ? 2 : 1"
            style="border-left: 1px solid #dadada; border-right: 1px solid #dadada"
            class="table-col"
          >
            <template v-if="canPlanComputed(month)">
              <div class="twoCol addRightBorder">
                <div class="segment-div">
                  {{
                    getSum(actuals, { selling_month: month.value, launch_type: "launch" }, "units_sold")
                      | commaSeparator
                  }}
                </div>
              </div>
              <div class="twoCol">
                <div class="segment-div">
                  {{ getUnitsForMonthByType("launch", month.value) }}
                </div>
              </div>
              <div class="twoCol addRightBorder">
                <div
                  v-for="segmentType in filterSegments"
                  :key="segmentType + '_1'"
                  :set="(isSegmentOffered = checkIfSegmentOffered(segmentType, 'launch'))"
                  class="segment-div"
                >
                  {{
                    getSum(
                      actuals,
                      {
                        segment: segmentType,
                        selling_month: month.value,
                        launch_type: "launch",
                      },
                      "units_sold"
                    ) | commaSeparator
                  }}
                </div>
              </div>
              <div class="twoCol">
                <div
                  v-for="segmentType in filterSegments"
                  :key="segmentType"
                  :set="(isSegmentOffered = checkIfSegmentOffered(segmentType, 'launch'))"
                  class="segment-div"
                >
                  <input
                    type="number"
                    min="0"
                    @blur="clearTooltip()"
                    @focus="showTooltip($event, 'launch', segmentType, month.value)"
                    warning="WARNING: You currently have set custom material level allocations. These will be overwritten for any custom values for this month and segment. Please proceed with caution."
                    :value="getMonthlyUnits(month.value, segmentType, 'launch')"
                    @change="
                      applySuggestedUnits(
                        { month: month.value, segment: segmentType, type: 'launch' },
                        $event.target.value,
                        $event.target
                      )
                    "
                    class="w-100"
                    :disabled="!checkSegmentDeadline(month.value, segmentType, 'launch')"
                    :style="
                      !checkSegmentDeadline(month.value, segmentType, 'launch') ? { backgroundColor: '#cc0000' } : {}
                    "
                  />
                </div>
              </div>
              <div class="twoCol addRightBorder">
                <div class="segment-div">
                  {{
                    getSum(actuals, { selling_month: month.value, launch_type: "launch" }, "amount")
                      | isValidNumber
                      | roundDecimals
                      | divideByThousand
                      | currencyFormat
                  }}
                </div>
              </div>
              <div class="twoCol">
                <div class="segment-div">
                  {{
                    getPriceForMonthByType("launch", month.value)
                      | isValidNumber
                      | roundDecimals
                      | divideByThousand
                      | currencyFormat
                  }}
                </div>
              </div>
            </template>
            <template v-else>
              <div>
                {{
                  getSum(
                    actuals,
                    {
                      selling_month: month.value,
                      launch_type: "launch",
                    },
                    "units_sold"
                  ) | commaSeparator
                }}
              </div>
              <div v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  getSum(
                    actuals,
                    {
                      segment: segmentType,
                      selling_month: month.value,
                      launch_type: "launch",
                    },
                    "units_sold"
                  ) | commaSeparator
                }}
              </div>
              <div>
                {{
                  getSum(
                    actuals,
                    {
                      selling_month: month.value,
                      launch_type: "launch",
                    },
                    "amount"
                  )
                    | isValidNumber
                    | roundDecimals
                    | divideByThousand
                    | currencyFormat
                }}
              </div>
            </template>
          </td>
          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" colspan="2" class="table-col">
            <div class="twoCol addRightBorder">
              <div class="segment-div">
                {{ getSum(actuals, { launch_type: "launch" }, "units_sold") | commaSeparator }}
              </div>
            </div>
            <div class="twoCol">
              <div class="segment-div">
                {{ getUnitsByYearByType("launch") }}
              </div>
            </div>
            <div class="twoCol addRightBorder">
              <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
                <template>
                  {{ getSum(actuals, { launch_type: "launch", segment: segmentType }, "units_sold") | commaSeparator }}
                </template>
              </div>
            </div>
            <div class="twoCol">
              <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
                <template>
                  {{ getUnitsByYearBySegment("launch", segmentType) }}
                </template>
              </div>
            </div>
            <div class="twoCol addRightBorder">
              <div class="segment-div">
                {{
                  getSum(actuals, { launch_type: "launch" }, "amount")
                    | isValidNumber
                    | roundDecimals
                    | divideByThousand
                    | currencyFormat
                }}
              </div>
            </div>
            <div class="twoCol">
              <div class="segment-div">
                {{ getPriceByYearByType("launch") | isValidNumber | roundDecimals | divideByThousand | currencyFormat }}
              </div>
            </div>
          </td>

          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <div class="segment-div display-inline">
              {{
                ((getUnitsByYearByType("launch") + getSum(actuals, { launch_type: "launch" }, "units_sold")) /
                  (getAllUnitsByYear() + getSum(actuals, {}, "units_sold")))
                  | percent
              }}
            </div>
            <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
              <template>
                {{
                  ((getUnitsByYearBySegment("launch", segmentType) +
                    getSum(actuals, { launch_type: "launch", segment: segmentType }, "units_sold")) /
                    (getUnitsByYearByType("launch") + getSum(actuals, { launch_type: "launch" }, "units_sold")))
                    | percent
                }}
              </template>
            </div>
            <div class="segment-div display-inline">-</div>
          </td>

          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <div class="segment-div display-inline">
              {{ getSum(stockUnits, { type: "launch" }, "retailer_stock_units") | commaSeparator }}
            </div>
            <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
              {{
                getSum(stockUnits, { segment: segmentType, type: "launch" }, "retailer_stock_units") | commaSeparator
              }}
            </div>
            <div class="segment-div display-inline">-</div>
          </td>

          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <template v-if="fetchingSegmentLevelData">
              <div class="segment-div display-inline">
                <Loading :show-text="false" />
              </div>
            </template>
            <template v-else>
              <div class="segment-div">
                {{ getAsOfUnitTotals(season.year, "launch") | commaSeparator }}
              </div>
              <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
                {{ getAsOfSegmentUnitTotals(season.year, "launch", segmentType) | commaSeparator }}
              </div>
              <div class="segment-div display-inline">-</div>
            </template>
          </td>
        </tr>
        <tr class="sub-rows">
          <th scope="row">
            <div class="segment-div inline-block-div">
              <p class="font-bold p-row">Migrating</p>
            </div>
            <div
              v-for="segmentType in filterSegments"
              :key="segmentType"
              :set="(isSegmentOffered = checkIfSegmentOffered(segmentType, 'migrating'))"
              class="segment-div"
            >
              <p
                :class="{ 'segment-not-offered': isSegmentOffered }"
                class="p-row text-ellipsis"
                v-tooltip.right="segmentType.length > 18 ? segmentType : ''"
              >
                {{ segmentType }}
              </p>
            </div>

            <p class="font-bold p-row segment-div inline-block-div">TL WHSL</p>
          </th>

          <td
            v-for="month in months"
            :key="month.shortName"
            :colspan="canPlanComputed(month) ? 2 : 1"
            style="border-left: 1px solid #dadada; border-right: 1px solid #dadada"
            class="table-col"
          >
            <template v-if="canPlanComputed(month)">
              <div class="twoCol addRightBorder">
                <div class="segment-div">
                  {{ getSum(actuals, { selling_month: month.value, launch_type: "migrating" }, "units_sold") }}
                </div>
              </div>
              <div class="twoCol">
                <div class="segment-div">
                  {{ getUnitsForMonthByType("migrating", month.value) }}
                </div>
              </div>
              <div class="twoCol addRightBorder">
                <div
                  v-for="segmentType in filterSegments"
                  :key="segmentType + '_2'"
                  :set="(isSegmentOffered = checkIfSegmentOffered(segmentType, 'migrating'))"
                  class="segment-div"
                >
                  {{
                    getSum(
                      actuals,
                      {
                        segment: segmentType,
                        selling_month: month.value,
                        launch_type: "migrating",
                      },
                      "units_sold"
                    )
                  }}
                </div>
              </div>
              <div class="twoCol">
                <div
                  v-for="segmentType in filterSegments"
                  :key="segmentType"
                  :set="(isSegmentOffered = checkIfSegmentOffered(segmentType, 'migrating'))"
                  class="segment-div"
                >
                  <input
                    type="number"
                    min="0"
                    @blur="clearTooltip()"
                    @focus="showTooltip($event, 'migrating', segmentType, month.value)"
                    warning="WARNING: You currently have set custom material level allocations. These will be overwritten for any custom values for this month and segment. Please proceed with caution."
                    :value="getMonthlyUnits(month.value, segmentType, 'migrating')"
                    @change="
                      applySuggestedUnits(
                        { month: month.value, segment: segmentType, type: 'migrating' },
                        $event.target.value,
                        $event.target
                      )
                    "
                    class="w-100"
                    :disabled="!checkSegmentDeadline(month.value, segmentType, 'migrating')"
                    :style="
                      !checkSegmentDeadline(month.value, segmentType, 'migrating') ? { backgroundColor: '#cc0000' } : {}
                    "
                  />
                </div>
              </div>
              <div class="twoCol addRightBorder">
                <div class="segment-div">
                  {{
                    getSum(actuals, { selling_month: month.value, launch_type: "migrating" }, "amount")
                      | isValidNumber
                      | roundDecimals
                      | divideByThousand
                      | currencyFormat
                  }}
                </div>
              </div>
              <div class="twoCol">
                <div class="segment-div">
                  {{
                    getPriceForMonthByType("migrating", month.value)
                      | isValidNumber
                      | roundDecimals
                      | divideByThousand
                      | currencyFormat
                  }}
                </div>
              </div>
            </template>
            <template v-else>
              <div>
                {{
                  getSum(
                    actuals,
                    {
                      selling_month: month.value,
                      launch_type: "migrating",
                    },
                    "units_sold"
                  )
                }}
              </div>
              <div v-for="segmentType in filterSegments" :key="segmentType">
                {{
                  getSum(
                    actuals,
                    {
                      segment: segmentType,
                      selling_month: month.value,
                      launch_type: "migrating",
                    },
                    "units_sold"
                  )
                }}
              </div>
              <div>
                {{
                  getSum(
                    actuals,
                    {
                      selling_month: month.value,
                      launch_type: "migrating",
                    },
                    "amount"
                  )
                    | isValidNumber
                    | roundDecimals
                    | divideByThousand
                    | currencyFormat
                }}
              </div>
            </template>
          </td>
          <td :colspan="2" class="table-col">
            <div class="twoCol addRightBorder">
              <div class="segment-div">
                {{ getSum(actuals, { launch_type: "migrating" }, "units_sold") }}
              </div>
            </div>
            <div class="twoCol">
              <div class="segment-div">
                {{ getUnitsByYearByType("migrating") }}
              </div>
            </div>
            <div class="twoCol addRightBorder">
              <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
                <template>
                  {{ getSum(actuals, { launch_type: "migrating", segment: segmentType }, "units_sold") }}
                </template>
              </div>
            </div>
            <div class="twoCol">
              <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
                <template>
                  {{ getUnitsByYearBySegment("migrating", segmentType) }}
                </template>
              </div>
            </div>
            <div class="twoCol addRightBorder">
              <div class="segment-div">
                {{
                  getSum(actuals, { launch_type: "migrating" }, "amount")
                    | isValidNumber
                    | roundDecimals
                    | divideByThousand
                    | currencyFormat
                }}
              </div>
            </div>
            <div class="twoCol">
              <div class="segment-div">
                {{
                  getPriceByYearByType("migrating") | isValidNumber | roundDecimals | divideByThousand | currencyFormat
                }}
              </div>
            </div>
          </td>

          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <div class="segment-div display-inline">
              {{
                ((getUnitsByYearByType("migrating") + getSum(actuals, { launch_type: "migrating" }, "units_sold")) /
                  (getAllUnitsByYear() + getSum(actuals, {}, "units_sold")))
                  | percent
              }}
            </div>

            <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
              <template>
                {{
                  ((getUnitsByYearBySegment("migrating", segmentType) +
                    getSum(actuals, { launch_type: "migrating", segment: segmentType }, "units_sold")) /
                    (getUnitsByYearByType("migrating") + getSum(actuals, { launch_type: "migrating" }, "units_sold")))
                    | percent
                }}
              </template>
            </div>
            <div class="segment-div display-inline">
              <div>&nbsp;</div>
            </div>
          </td>
          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <div class="segment-div display-inline">
              {{ getSum(stockUnits, { type: "migrating" }, "retailer_stock_units") }}
            </div>
            <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
              {{ getSum(stockUnits, { segment: segmentType, type: "migrating" }, "retailer_stock_units") }}
            </div>
            <div class="segment-div display-inline">-</div>
          </td>

          <td style="border-left: 1px solid #dadada; border-right: 1px solid #dadada" class="table-col">
            <template v-if="fetchingSegmentLevelData">
              <div>
                <Loading :show-text="false" />
              </div>
            </template>
            <template v-else>
              <div class="segment-div display-inline">
                {{ getAsOfUnitTotals(season.year, "migrating") }}
              </div>
              <div v-for="segmentType in filterSegments" :key="segmentType" class="segment-div">
                {{ getAsOfSegmentUnitTotals(season.year, "migrating", segmentType) }}
              </div>
              <div class="segment-div display-inline">-</div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API, graphqlOperation } from "aws-amplify";
import { filtersMixin } from "@/mixins/filters";
import Loading from "@/components/common/Loading";
import Tooltip from "@/components/common/Tooltip";
import { getMonthlyTotals, getStockUnitsBySoldToId, getCategoryIdList } from "@/graphql/queries";
import { saveCustomMaterialAllocation, deleteOldCustomMaterialAllocations } from "@/graphql/mutations";

export default {
  mixins: [filtersMixin],
  components: {
    Loading,
    Tooltip,
  },
  data() {
    return {
      actualsLocal: [],
      fetchingSegmentLevelData: false,
      invalidInputValue: false,
      hasSavedMonths: false,
      splitSelected: 0,
      pageError: "",
      lastUpdated: "",
      localMonthsIndex: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
      tooltip: null,
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "months",
      "monthsIndex",
      "product",
      "season",
      "suggestedBookings",
      "actuals",
      "inventory",
      "openOrders",
      "segmentLevelData",
      "currentSeasonMutation",
      "planningConfig",
      "currentSeason",
      "previousSeason",
      "stockUnits",
      "stockUnitsAsOf",
      "currentSeasonYear",
      "earliestMonthCanPlanGlobal",
      "materialData",
      "useCalculated",
    ]),

    getAllPriceByYear() {
      return () => {
        const val = this.$materialAllocation.plannedPriceByYearForAllOrdertypes();
        return val;
      };
    },

    getAllUnitsByYear() {
      return () => {
        const val = this.$materialAllocation.plannedUnitsByYearForAllOrdertypes();
        return val;
      };
    },

    getAllUnitsByMonth() {
      return (month) => {
        const val = this.$materialAllocation.plannedUnitsByMonthForAllOrdertypes(month);
        return val;
      };
    },

    getAllPriceByMonth() {
      return (month) => {
        const val = this.$materialAllocation.plannedPriceByMonthForAllOrdertypes(month);
        return val;
      };
    },

    getPriceByYearByType() {
      return (type) => {
        const val = this.$materialAllocation.plannedPriceByYearForOrdertype(type);
        return val;
      };
    },

    getUnitsByYearByType() {
      return (type) => {
        const val = this.$materialAllocation.plannedUnitsByYearForOrdertype(type);
        return val;
      };
    },

    getUnitsForMonthByType() {
      return (type, month) => {
        const val = this.$materialAllocation.plannedUnitsByMonthForOrdertype(type, month);
        return val;
      };
    },

    getPriceForMonthByType() {
      return (type, month) => {
        const val = this.$materialAllocation.plannedPriceByMonthForOrdertype(type, month);
        return val;
      };
    },

    getPriceByYearBySegment() {
      return (type, segment) => {
        const val = this.$materialAllocation.plannedPriceByYearForSegment(type, segment);
        return val;
      };
    },

    getUnitsByYearBySegment() {
      return (type, segment) => {
        const val = this.$materialAllocation.plannedUnitsByYearForSegment(type, segment);
        return val;
      };
    },

    getPriceByMonthBySegment() {
      return (type, segment, month) => {
        const val = this.$materialAllocation.plannedPriceByMonthForSegment(type, segment, month);
        return val;
      };
    },

    getUnitsByMonthBySegment() {
      return (type, segment, month) => {
        const val = this.$materialAllocation.plannedUnitsByMonthForSegment(type, segment, month);
        return val;
      };
    },

    getMonthlyUnits() {
      return (month, segmentname, type) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const ret = this.$materialAllocation.plannedUnitsByMonthForSegment(type, segmentname, month);
        return ret;
      };
    },

    isCustomAllocation() {
      return (type, segment, month) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const ret = this.$materialAllocation.isSegmentMonthCustom(type, segment, month);
        return ret;
      };
    },

    canPlanComputed() {
      return (month) => {
        return this.canPlanForMonth(month) ? 2 : 1;
      };
    },

    filterSegments() {
      const segments = this.product.segments;
      if (typeof segments !== "undefined" && segments !== null && segments.length > 0) {
        return segments;
      }
      return this.segments;
    },

    checkSegmentDeadline() {
      return (month, segment, type) => {
        let materialExists = this.materialData.filter((x) => x.type == type && x.segment == segment);
        if (materialExists.length === 0) {
          return false;
        }
        const material = materialExists.reduce((prev, current) => {
          return new Date(prev.shelfdate) < new Date(current.shelfdate) ? prev : current;
        });

        const columndate = new Date(month < 4 ? this.season.year + 1 : this.season.year, month - 1, 1);
        const sdate = this.getDateInEST(material.shelfdate);
        const adjustedShelfDate = new Date(sdate.getFullYear(), sdate.getMonth(), 1);
        return columndate >= adjustedShelfDate;
      };
    },
  },

  methods: {
    showTooltip(event, type, segment, month) {
      this.$warning.hide();
      if (!this.isCustomAllocation(type, segment, month)) {
        return;
      }
      const input = event.target;
      this.$warning.show(event, input.getAttribute("warning"), 180);
    },

    getAsOfSegmentUnitTotals(year, type, segment) {
      const filteredItems = this.openOrders.filter(
        (item) =>
          (item.request_year < year || (item.request_year === year && item.request_month < 4)) &&
          item.segment === segment &&
          item.type === type
      );
      const amount = filteredItems.reduce((total, item) => total + item.units, 0);
      return amount;
    },

    getAsOfUnitTotals(year, type) {
      const filteredItems = this.openOrders.filter(
        (item) =>
          (item.request_year < year || (item.request_year === year && item.request_month < 4)) && item.type === type
      );
      const amount = filteredItems.reduce((total, item) => total + item.units, 0);
      return amount;
    },

    getAsOfUnitTotalsAll(year) {
      const filteredItems = this.openOrders.filter(
        (item) => item.request_year < year || (item.request_year === year && item.request_month < 4)
      );
      const amount = filteredItems.reduce((total, item) => total + item.units, 0);
      return amount;
    },

    clearTooltip() {
      this.$warning.hide();
    },

    filterOpenOrdersByMonth(month) {
      return this.openOrders
        .filter((order) => order.request_month === month.value)
        .reduce((total, order) => total + order.units, 0);
    },
    async savePlannedData() {
      this.$overlay.show("Saving your planned orders");
      this.pageError = "";
      const customMaterials = this.$materialAllocation.getMaterialsWithNonZeroPlans();
      let oldDeleted = false;
      for (const m in customMaterials) {
        const material = customMaterials[m];
        let saveObj = {};
        saveObj["id"] = 0;
        saveObj["soldto_id"] = this.planningConfig.retailer.soldto_id;
        saveObj["material_number"] = material.materialNumber;
        saveObj["amount"] = material.price;
        saveObj["month_index"] = material.month;
        saveObj["segment"] = material.segmentName;
        saveObj["type"] = material.ordertypeName;
        saveObj["units_entered"] = material.planned;
        saveObj["category"] = this.planningConfig.product.value;
        //Change to current season instead of year
        saveObj["year"] = this.season.year;
        saveObj["active"] = 1;
        saveObj["modified_by"] = this.user.name;
        try {
          if (!oldDeleted) {
            await API.graphql(
              graphqlOperation(deleteOldCustomMaterialAllocations, { saveCustomMaterialAllocation: saveObj })
            );
            oldDeleted = true;
          }
          await API.graphql(graphqlOperation(saveCustomMaterialAllocation, { saveCustomMaterialAllocation: saveObj }));
        } catch (err) {
          this.pageError = err;
          this.$overlay.hide("Your planned orders did not save properly. Please try again");
          return;
        }
      }
      this.$materialAllocation.isDirty = false;
      this.lastUpdated = new Date().toLocaleString();
      if (!this.isSaveDisabled && this.lastUpdated !== "") {
        if (this.$store.state.wasLastSaveFromSaveButton) {
          this.$overlay.hide("Your planned orders were successfully saved!");
        }
      }
    },
    resolveMonth(monthIndex) {
      return this.months.find((x) => x.value == monthIndex).name;
    },

    canPlanForMonth(month) {
      if (month.value < this.earliestMonthCanPlanGlobal) {
        if (month.value == 1 || month.value == 2 || month.value == 3) {
          if (this.earliestMonthCanPlanGlobal > 3) {
            return true;
          } else {
            if (month < this.earliestMonthCanPlanGlobal) {
              return false;
            } else {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },

    canPlanForMonthIndex(index) {
      let month = this.localMonthsIndex.indexOf(index);
      if (month.value < this.earliestMonthCanPlanGlobal) {
        if (month.value == 1 || month.value == 2 || month.value == 3) {
          if (this.earliestMonthCanPlanGlobal > 3) {
            return true;
          } else {
            if (month < this.earliestMonthCanPlanGlobal) {
              return false;
            } else {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },

    applySuggestedUnits(booking, value, input) {
      if (value === null || value === "" || value < 0) {
        input.value = 0;
        value = 0;
      }
      booking.units = parseInt(value);
      this.$events.emit("updateSuggestedBookings", booking);
    },

    checkIfSegmentOffered(segment, type) {
      var dummy = ("segment: " + segment, " ,type: " + type);

      if (dummy.length > 0) {
        return true;
      }
      return true;
    },

    async loadSavedMonthlyTotals() {
      this.suggestedBookings = [];
      try {
        const response = await API.graphql({
          query: getMonthlyTotals,
          variables: {
            soldto_id: this.planningConfig.retailer.soldto_id,
            category: this.planningConfig.product.value,
            season: this.currentSeasonMutation,
          },
        });

        const data = response.data.getMonthlyTotals;
        if (data.length > 0) {
          this.$store.commit("setMaterialSplitValue", data[0].split);
          data.forEach((bookingData) => {
            let booking = {
              units: bookingData.units,
              month: bookingData.booking_date,
              segment: bookingData.segment,
              type: bookingData.type,
            };
            for (let i = 0; i < this.months.length; i++) {
              if (this.months[i].value === bookingData.booking_date && this.canPlanForMonth(this.months[i])) {
                this.$store.commit("setSuggestedBooking", booking);
              }
            }
          });
          this.hasSavedMonths = true;
        } else {
          this.$store.commit("setMaterialSplitValue", false);
        }
      } catch (err) {
        this.pageError = err;
      }
    },

    calcActualsTotal(type, year, segmentType, season) {
      let total = 0;
      for (let j = 0; j < this.months.length; j++) {
        if (!this.canPlanForMonth(this.months[j])) {
          switch (type) {
            case "segment":
              total += this.getSum(
                this.actuals,
                { selling_year: year, selling_month: this.months[j].value, segment: segmentType, launch_type: season },
                "units_sold"
              );
              break;
            case "season":
              total += this.getSum(
                this.actuals,
                { selling_year: year, selling_month: this.months[j].value, launch_type: season },
                "units_sold"
              );
              break;
            case "total_units":
              total += this.getSum(
                this.actuals,
                { selling_year: year, selling_month: this.months[j].value },
                "units_sold"
              );
              break;
            case "total_amount":
              total += this.getSum(
                this.actuals,
                { selling_year: year, selling_month: this.months[j].value, launch_type: season },
                "amount"
              );
              break;
            case "full_amount":
              total += this.getSum(this.actuals, { selling_year: year, selling_month: this.months[j].value }, "amount");
              break;
            default:
              console.log("default logic");
          }
        }
      }
      return total;
    },

    async fetchStockUnits() {
      const categoryIdsData = await API.graphql({
        query: getCategoryIdList,
        variables: {
          category_id: this.product.category_id,
        },
      });

      const categoryIds = categoryIdsData.data.getCategoryIdList[0].ids;

      const response = await API.graphql({
        query: getStockUnitsBySoldToId,
        variables: {
          sold_to_id: this.planningConfig.retailer.soldto_id,
          category_id: categoryIds,
          begin_year: this.currentSeasonYear,
          top_planning_input_id: this.product.top_planning_inputs_id.toLowerCase(),
        },
      });

      const data = response.data.getStockUnitsBySoldToId;
      if (data.length > 0) {
        this.$store.commit("setStockUnitsAsOf", `As of: ${data[0].end_day}`);
      }
      this.$store.commit("setStockUnits", data);
    },
  },
  mounted() {
    this.$events.on("SegmentLevelSelector", (data) => {
      this.fetchingSegmentLevelData = data.busy;
    });

    this.fetchStockUnits();

    this.$events.on("saveSuggestedMonthlyBookings", async (data, gotosettings) => {
      await this.savePlannedData();
      if (gotosettings !== undefined && gotosettings.gotosettings === true) {
        this.$router.push("./setup");
      }
    });
    this.$events.on("selectMaterialAllocation", (data) => {
      this.splitSelected = data.value;
    });
  },
  beforeDestroy() {
    this.clearTooltip();
    this.$events.off("SegmentLevelSelector");
    this.$events.off("saveSuggestedMonthlyBookings");
  },
};
</script>

<style scoped lang="scss">
$header_column_width: 120px;
$input_column_width: 65px;

.twoCol {
  width: 50%;
  display: inline-block;
}

.addRightBorder {
  border-right: 1px solid #dadada;
}

.labels_th {
  min-width: $header_column_width;
  width: $header_column_width;
}

.months_th {
  min-width: $input_column_width;
  width: $input_column_width;
}

.total-col {
  min-width: 120px;
}

.table td,
.table th {
  padding: 0.75rem 0.25rem;
}

.compact-dropdown {
  width: 90px;
}

input.segment-not-offered {
  background-color: #ccc;
}

span.segment-not-offered {
  color: #ccc;
}

.segment-div {
  margin: 5px 0;
}

.display-inline {
  display: inline-block;
}

.text-ellipsis {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline-block-div {
  display: inline-block;
}
</style>
