<template>
  <div class="table_material row">
    <div class="col-12">
      <div class="loading" v-if="loading">
        <Loading />
      </div>
      <div v-else>
        <div v-for="{ type, label } in productTypes" :key="type" :set="(data = productTypeData(type))">
          <h5 class="mb-0">
            <span>{{ season.label }}</span
            ><b>{{ label }} - Based on {{ selectedOption }}</b>
          </h5>

          <div class="row">
            <div class="col-12 horizontal__scroll">
              <table class="table">
                <thead>
                  <tr>
                    <th>Material / Description</th>
                    <th>Family</th>
                    <th>Segment</th>
                    <th>WHSL</th>
                    <th
                      style="border: 1px solid #dadada"
                      class="alignAndBorderThick"
                      v-for="month in months"
                      :key="month.shortName"
                      :colspan="!canPlanBasedOnMonth(month) ? 1 : 2"
                    >
                      {{ month.shortName }}
                    </th>
                    <th>Units TL</th>
                    <th>WHSL$ TL</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <template v-for="month in months">
                      <th
                        style="border: 1px solid #dadada"
                        class="alignAndBorder"
                        :key="month.shortName + '-open'"
                        title="Open Orders"
                      >
                        O
                      </th>
                      <th
                        style="border: 1px solid #dadada"
                        class="alignAndBorder"
                        v-if="canPlanBasedOnMonth(month)"
                        :key="month.shortName + '-planned'"
                        title="Planned Orders"
                      >
                        P
                      </th>
                    </template>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in data" :key="row.material">
                    <th :title="getReferenceTitle(row.material)">{{ row.material }} - {{ row.description }}</th>
                    <th class="image">
                      <img :src="`https://bauer.a.bigcontent.io/v1/static/${row.family.toLowerCase()}-icon`" />
                    </th>
                    <th>{{ row.segment }}</th>
                    <th>{{ row.amount | currencyFormat }}</th>
                    <template v-for="month in months">
                      <th :key="month.shortName + 'op'" class="alignAndBorder">
                        {{
                          getSum(
                            actuals,
                            {
                              selling_year: month.value > 3 ? season.year : parseInt(season.year) + 1,
                              selling_month: month.value,
                              material_number: row.material,
                            },
                            "units_sold"
                          )
                        }}
                      </th>
                      <th v-if="canPlanBasedOnMonth(month)" :key="month.shortName + 'pl'" class="alignAndBorder">
                        <input
                          @input="userAllocationChange($event, row, month)"
                          :disabled="!canEnterData(row, month)"
                          type="number"
                          min="0"
                          class="w-100"
                          :value="getMaterialUnits(month, type, row) | roundDecimals"
                          :style="!canEnterData(row, month) ? { backgroundColor: '#cc0000' } : {}"
                        />
                      </th>
                    </template>
                    <template>
                      <th>
                        {{
                          (getUnitsByYearForMaterial(type, row.segment, row.family, row.gender, row.material) +
                            getSum(
                              actuals,
                              {
                                selling_year: season.year,
                                material_number: row.material,
                              },
                              "units_sold"
                            ))
                            | roundDecimals
                        }}
                      </th>

                      <th>
                        {{
                          (getPriceByYearForMaterial(type, row.segment, row.family, row.gender, row.material) +
                            +getSum(
                              actuals,
                              {
                                selling_year: season.year,
                                material_number: row.material,
                              },
                              "amount"
                            ))
                            | roundDecimals
                            | divideByThousand
                            | currencyFormat
                        }}
                      </th>
                    </template>
                  </tr>
                  <tr class="totals">
                    <th class="heading">UNITS TL</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <template>
                      <template v-for="(launch, index) in launchData">
                        <th :key="index + 'ope'" class="alignAndBorder">
                          {{
                            getSum(
                              actuals,
                              {
                                selling_year: index + 4 > 12 ? parseInt(season.year) + 1 : season.year,
                                selling_month: index + 4 > 12 ? index - 8 : index + 4,
                                launch_type: type,
                              },
                              "units_sold"
                            )
                              | roundDecimals
                              | commaSeparator
                          }}
                        </th>
                        <th v-if="canPlanBasedOnMonthFromIndex(index)" :key="index + 'pla'" class="alignAndBorder">
                          {{
                            getUnitsForMonthByType(type, index + 4 > 12 ? index - 8 : index + 4)
                              | roundDecimals
                              | commaSeparator
                          }}
                        </th>
                      </template>

                      <th class="heading">
                        {{
                          (getUnitsForYearByType(type) +
                            getSum(
                              actuals,
                              {
                                selling_year: season.year,
                                launch_type: type,
                              },
                              "units_sold"
                            ) +
                            getSum(
                              actuals,
                              {
                                selling_year: season.year + 1,
                                launch_type: type,
                              },
                              "units_sold"
                            ))
                            | roundDecimals
                            | commaSeparator
                        }}
                      </th>
                    </template>
                    <th>-</th>
                  </tr>
                  <tr class="totals">
                    <th class="heading">WHSL TL</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <template>
                      <template v-for="(launch, index) in launchData">
                        <th :key="20 + index + 'o'" class="alignAndBorder">
                          {{
                            getSum(
                              actuals,
                              {
                                selling_year: index + 4 > 12 ? parseInt(season.year) + 1 : season.year,
                                selling_month: index + 4 > 12 ? index - 8 : index + 4,
                                launch_type: type,
                              },
                              "amount"
                            )
                              | roundDecimals
                              | divideByThousand
                              | currencyFormat
                          }}
                        </th>
                        <th v-if="canPlanBasedOnMonthFromIndex(index)" :key="20 + index + 'p'" class="alignAndBorder">
                          {{
                            getPriceForMonthByType(type, index + 4 > 12 ? index - 8 : index + 4)
                              | roundDecimals
                              | divideByThousand
                              | currencyFormat
                          }}
                        </th>
                      </template>
                      <th>-</th>
                      <th class="heading">
                        {{
                          (getPriceForYearByType(type) +
                            getSum(
                              actuals,
                              {
                                selling_year: season.year,
                                launch_type: type,
                              },
                              "amount"
                            ) +
                            getSum(
                              actuals,
                              {
                                selling_year: season.year + 1,
                                launch_type: type,
                              },
                              "amount"
                            ))
                            | roundDecimals
                            | divideByThousand
                            | currencyFormat
                        }}
                      </th>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, clone } from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import { mapGetters } from "vuex";
import Loading from "@/components/common/Loading";
import { filtersMixin } from "@/mixins/filters";
import {
  getGenderSort,
  getPgsSort,
  fetchMaterials,
  getCustomMaterialAllocations,
  getAllReferenceUpcs,
  getPreviousSellInData,
} from "@/graphql/queries";

const MATERIAL_DATA_TOTALS = {
  launch: {
    total: 0,
  },
  migrating: {
    total: 0,
  },
};

export default {
  mixins: [filtersMixin],
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      productTypes: [
        { type: "launch", label: "Launch Suggested" },
        { type: "migrating", label: "Migrating Suggested" },
      ],
      filter: 111,
      customAllocationsLoaded: false,
      materialDataLocal: [],
      materialDataTotals: cloneDeep(MATERIAL_DATA_TOTALS),
      materialWeights: [],
      monthlyMaterialWeights: [],
      familiesWeight: {
        launch: {
          vapor: 0,
          supreme: 0,
          nexus: 0,
          bauer: 0,
          nme: 0,
          prodigy: 0,
          core: 0,
          seasonal: 0,
          tuuk: 0,
          mission: 0,
          street: 0,
        },
        migrating: {
          vapor: 0,
          supreme: 0,
          nexus: 0,
          bauer: 0,
          nme: 0,
          prodigy: 0,
          core: 0,
          seasonal: 0,
          tuuk: 0,
          mission: 0,
          street: 0,
        },
      },
      pgssort: [],
      segments: ["Elite", "Performance", "Recreational", "All"],
      weights: {},
      localAllData: [],
      totalData: [],
      launchData: [],
      launchTotals: {
        units: 0,
        launch: 0,
      },
      migratingData: [],
      migratingTotals: {
        units: 0,
        launch: 0,
      },
      remainMaterials: [],
      selectedOption: "BTH Bookings",
      refUpcs: [],
      recompute: 0,
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "months",
      "retailer",
      "product",
      "season",
      "shipTo",
      "actuals",
      "suggestedBookings",
      "planningConfig",
      "earliestMonthCanPlanGlobal",
      "latestMonthCanPlan",
      "materialData",
      "customSavedAllocationValues",
      "calculatedAllocationValues",
      "useCalculated",
      "bookingYear",
    ]),
    filterSegments() {
      const segments = this.product.segments;
      if (typeof segments !== "undefined" && segments !== null && segments.length > 0) {
        return segments;
      }
      return this.segments;
    },
    canEnterData() {
      return (material, month) => {
        const columndate = new Date(month.value < 4 ? this.season.year + 1 : this.season.year, month.value - 1, 1);
        const sdate = this.getDateInEST(material.shelfdate);
        const adjustedShelfDate = new Date(sdate.getFullYear(), sdate.getMonth(), 1);
        return columndate >= adjustedShelfDate;
      };
    },
    getUnitsForMonthByType() {
      return (type, month) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val = this.$materialAllocation.plannedUnitsByMonthForOrdertype(type, month);
        return val;
      };
    },

    getPriceForMonthByType() {
      return (type, month) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val = this.$materialAllocation.plannedPriceByMonthForOrdertype(type, month);
        return val;
      };
    },

    getUnitsForYearByType() {
      return (type) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val = this.$materialAllocation.plannedUnitsByYearForOrdertype(type);
        return val;
      };
    },

    getPriceForYearByType() {
      //ok
      return (type) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val = this.$materialAllocation.plannedPriceByYearForOrdertype(type);
        return val;
      };
    },

    getUnitsByYearForMaterial() {
      //ok
      return (ordertypeName, segmentName, familyName, genderName, material) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val = this.$materialAllocation.plannedUnitsByYearForMaterial(
          ordertypeName,
          segmentName,
          familyName,
          genderName,
          material
        );
        return val;
      };
    },

    getPriceByYearForMaterial() {
      //ok
      return (ordertypeName, segmentName, familyName, genderName, material) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const val = this.$materialAllocation.plannedPriceByYearForMaterial(
          ordertypeName,
          segmentName,
          familyName,
          genderName,
          material
        );
        return val;
      };
    },

    getMaterialUnits() {
      return (month, type, row) => {
        this.recompute = this.$materialAllocation.changeEvent;
        const ret = this.$materialAllocation.getPlannedMaterialValue(
          type.toLowerCase(),
          row.segment,
          row.family,
          row.gender,
          row.material,
          month.value
        );
        return ret;
      };
    },
  },
  created() {
    this.$store.commit("setUnsavedChangesFlag", false);
    this.$store.state.allocationRemainders = {};

    this.resetTotals();
    this.fetchMaterials();
    this.$events.on("selectMaterialAllocation", (data) => {
      this.selectedOption = data.description;
      this.filter = data.value;
      const handleEvent = async () => {
        await this.fetchMaterials();
        this.monthlyMaterialWeights = [];
        this.$store.state.allocationRemainders = {};
        this.suggestedBookings.forEach((booking) => {
          this.doAllocationRounding(booking);
        });
      };

      handleEvent();
    });

    this.$events.on("suggestedBookingsUpdated", (booking) => {
      this.doAllocationRounding(booking);
      this.addMaterialTotals(this.materialDataLocal);
    });
  },

  beforeDestroy() {
    this.$materialAllocation.reset();
    this.$events.off("updateSuggestedBookings");
    this.$events.off("selectMaterialAllocation");
    this.$events.off("suggestedBookingsUpdated");
    this.$store.commit("setUserChangedFlag", false);
  },
  methods: {
    getReferenceTitle(material) {
      const foundRefObj = this.refUpcs.find((x) => x["new_material_number"] == material);
      if (foundRefObj != null) {
        return `Reference: ${foundRefObj["ref_description"]} - ${foundRefObj["ref_material"]} | Time Frame: ${foundRefObj["begin_algorithm_date"]}-${foundRefObj["end_algorithm_date"]}`;
      } else {
        return "";
      }
    },
    async getAllReferenceUpcs() {
      const response = await API.graphql({
        query: getAllReferenceUpcs,
        variables: {
          category: this.product.id,
          sorg: this.retailer.sorg,
        },
      });
      const data = response.data.getAllReferenceUpcs;
      this.refUpcs = data;
    },

    isMaterialAllocatable(material, month) {
      const bookingmonth = new Date(month < 4 ? this.season.year + 1 : this.season.year, month - 1, 1);
      const sdate = this.getDateInEST(material.shelfdate);
      const adjustedShelfDate = new Date(sdate.getFullYear(), sdate.getMonth(), 1);
      return bookingmonth >= adjustedShelfDate;
    },

    adjustWeights(arr, month, zeroit = false) {
      let weight = 0;
      let totalweight = 0;
      if (!zeroit) {
        totalweight = arr.reduce((acc, item) => acc + item.weight, 0);
      }
      arr.forEach((item) => {
        const ii = this.monthlyMaterialWeights.filter((x) => x.material === item.material && x.month === month);
        if (ii == null || ii.length === 0) {
          this.monthlyMaterialWeights.push({
            material: item.material,
            month: month,
            weight: zeroit ? weight : item.weight / totalweight,
          });
        } else {
          ii.weight = zeroit ? weight : item.weight / totalweight;
        }
      });
    },

    doAllocationRounding(booking) {
      this.remainMaterials = [];
      const nonmaterials = this.materialDataLocal.filter(
        (x) => x.type === booking.type && x.segment === booking.segment && !this.isMaterialAllocatable(x, booking.month)
      );

      const materials = this.materialDataLocal.filter(
        (x) => x.type === booking.type && x.segment === booking.segment && this.isMaterialAllocatable(x, booking.month)
      );

      let allmaterials = materials;

      if (nonmaterials.length > 0) {
        this.adjustWeights(materials, booking.month);
        this.adjustWeights(nonmaterials, booking.month, true);
        allmaterials = [...nonmaterials, ...materials];
      }

      let totalAllocated = 0;

      allmaterials.forEach((material) => {
        const { rounded, remainder } = this.calculateRemainder(material, booking);
        totalAllocated += rounded;

        this.$materialAllocation.setPlannedMaterialValue(
          booking.type,
          booking.segment,
          material.family,
          material.gender,
          material.material,
          booking.month,
          rounded,
          false,
          false
        ); // Adjust the value as needed

        if (remainder !== 0) {
          this.remainMaterials.push(material);
          this.handleRemainders(material, booking, remainder);
        }
      });

      if (totalAllocated !== booking.units) {
        this.allocateRemainingUnits(booking, totalAllocated);
      }
    },

    calculateRemainder(material, booking) {
      const customweight = this.monthlyMaterialWeights.filter(
        (x) => x.material === material.material && x.month === booking.month
      );
      const original =
        this.getData(this.suggestedBookings, {
          month: booking.month,
          type: booking.type,
          segment: material.segment,
        }).units * (customweight !== undefined && customweight.length === 1 ? customweight[0].weight : material.weight);

      const rounded = Math.floor(original);
      const remainder = Math.abs(original - rounded);
      return { rounded, remainder };
    },

    handleRemainders(material, booking, remainder) {
      let pushObj = {
        month: booking.month,
        type: material.type,
        segment: material.segment,
        remainder: remainder,
        material: material.material,
        model: material.model,
        family: material.family,
        gender: material.gender,
      };

      // Check for existing entry
      const typeExists = this.$store.state.allocationRemainders[material.type];
      const existingEntry = this.$store.state.allocationRemainders[material.type]?.[material.segment];
      const everythingFound = existingEntry?.find(
        (entry) => entry.material === pushObj.material && entry.month === pushObj.month
      );

      if (everythingFound) {
        // Update existing entry
        everythingFound.remainder = pushObj.remainder;
      } else {
        // Push new entry
        if (existingEntry) {
          existingEntry.push(pushObj);
        } else {
          if (!typeExists) {
            this.$store.state.allocationRemainders[material.type] = {
              [material.segment]: [pushObj],
            };
          } else {
            this.$store.state.allocationRemainders[material.type][material.segment] = [pushObj];
          }
        }
      }
    },

    allocateRemainingUnits(booking, totalAllocated) {
      const diff = booking.units - totalAllocated;

      try {
        let matchingArray = this.$store.state.allocationRemainders[booking.type][booking.segment].filter(
          (x) => x.month == booking.month
        );

        // Sort the matching array in descending order of remainder
        matchingArray.sort((a, b) => b.remainder - a.remainder);

        // Keep only the entries with the highest remainder values
        if (diff < matchingArray.length) {
          matchingArray = matchingArray.slice(0, diff);
        }

        // Allocate remaining units to the materials with the highest remainders
        matchingArray.forEach((material) => {
          this.$materialAllocation.setPlannedMaterialValue(
            booking.type,
            booking.segment,
            material.family,
            material.gender,
            material.material,
            booking.month,
            1,
            true,
            false
          ); // Adjust the value as needed
        });
      } catch (error) {
        console.error("allocateRemainingUnits:" + error);
      }
    },

    userAllocationChange(e, row, mon) {
      const numberUsed = e.target.value === "" ? 0 : e.target.value;
      this.$materialAllocation.setPlannedMaterialValue(
        row.type.toLowerCase(),
        row.segment,
        row.family,
        row.gender,
        row.material,
        mon.value,
        parseInt(numberUsed)
      );
      const bookingTotal = this.$materialAllocation.plannedUnitsByMonthForSegment(
        row.type.toLowerCase(),
        row.segment,
        mon.value
      );
      let booking = { month: mon.value, segment: row.segment, type: row.type.toLowerCase(), units: bookingTotal };
      let suggestedBooking = { ...booking };
      this.$store.commit("setSuggestedBooking", suggestedBooking);
    },

    resolveMonth(monthIndex) {
      return this.months.find((x) => x.value == monthIndex).name;
    },

    getCellClass(month) {
      return {
        editableCell: this.canPlanBasedOnMonth(month),
      };
    },

    productTypeData(type) {
      return this.materialDataLocal.filter((o) => o.type === type);
    },

    canPlanBasedOnMonth(month) {
      if (month.value < this.earliestMonthCanPlanGlobal) {
        if (month.value == 1 || month.value == 2 || month.value == 3) {
          if (this.earliestMonthCanPlanGlobal > 3) {
            return true;
          } else {
            if (month < this.earliestMonthCanPlanGlobal) {
              return false;
            } else {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },

    canPlanBasedOnMonthFromIndex(index) {
      let month = this.months[index];
      if (month.value < this.earliestMonthCanPlanGlobal) {
        if (month.value == 1 || month.value == 2 || month.value == 3) {
          if (this.earliestMonthCanPlanGlobal > 3) {
            return true;
          } else {
            if (month < this.earliestMonthCanPlanGlobal) {
              return false;
            } else {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },

    checkDeadline(month) {
      const index = this.months.indexOf(month);
      const deadline = new Date(this.product.deadlines[index]);
      return deadline < new Date();
    },

    checkDeadlineFromIndex(index) {
      const deadline = new Date(this.product.deadlines[index]);
      return deadline < new Date();
    },

    resetTotals() {
      this.launchTotals = {
        units: 0,
        amount: 0,
      };
      this.migratingTotals = {
        units: 0,
        amount: 0,
      };

      const template = { units: 0, amount: 0, Elite: 0, Performance: 0, Recreational: 0, All: 0, Seasonal: 0, Core: 0 };
      this.launchData = Array(this.season.months.length)
        .fill()
        .map(() => clone(template));
      this.migratingData = Array(this.season.months.length)
        .fill()
        .map(() => clone(template));
      this.totalData = Array(this.season.months.length).fill({ units: 0, amount: 0 });
      this.localAllData = [];

      // this.$store.commit('setMaterialData', []);
    },

    async fetchMaterials() {
      this.$overlay.show("Loading material data...");
      let data = [];

      //top-planning-unit and material_desc added to satisfy sorting criteria : AE-1
      const variables = {
        soldto_id: this.retailer.soldto_id,
        category: this.product.id,
        start_year: 0,
        end_year: this.planningConfig.season.year,
        start_month: 0,
        end_month: 0,
        sorg: this.retailer.sorg,
        currency_type: "PR00",
        smu_group_sec: this.retailer.SEC,
        smu_group_sdc: this.retailer.SDC,
        market: this.retailer.market,
        filter: this.filter,
        market_filter: this.retailer.marketQuery,
        region: this.retailer.region,
        category_id: this.product.category_id,
        ship_to_id: this.retailer.shipto_id,
        top_planning_input_id: this.product.top_planning_inputs_id.toLowerCase(),
        main_description: this.product.main_description.toLowerCase(),
      };
      const psort = await API.graphql({ query: getPgsSort });
      const gsort = await API.graphql({ query: getGenderSort });

      const materialDatatmp = await API.graphql(graphqlOperation(fetchMaterials, variables));
      data = materialDatatmp.data.fetchMaterials;

      this.populateMatertialDataTotals(data);

      this.adjustMaterialWeightsByFamily(data);

      data = this.calculateMaterialsWeights(data);
      this.populateWeights(data);

      // POPULATE localAllData[],
      this.addMaterialTotals(data);

      // POPULATE materialData[]
      this.materialDataLocal = data;

      if (!this.customAllocationsLoaded) {
        this.$materialAllocation.initializeFromData(data, psort.data.getPgsSort, gsort.data.getGenderSort);
        this.loadCustomAllocations();
        this.customAllocationsLoaded = true;
      }

      this.$overlay.hide();
    },

    checkIfCanPlan(input) {
      return input >= this.earliestMonthCanPlanGlobal;
    },

    customSort(data) {
      return data.sort(
        (a, b) =>
          a.family.localeCompare(b.family) ||
          a.segment.localeCompare(b.segment) ||
          a.model.localeCompare(b.model) ||
          b.amount - a.amount
      );
    },

    populateMatertialDataTotals(materials) {
      this.materialDataTotals = cloneDeep(MATERIAL_DATA_TOTALS);

      // ITERATE MATERIALS AND AGGREGATE WEIGHT TOTAL SUM AND SEGMENTS SUM
      materials.forEach(({ family, segment, type, weight, shelfdate }) => {
        const month = new Date(shelfdate).getUTCMonth() + 1;
        const year = new Date(shelfdate).getUTCFullYear();
        const newPlan = { segment, type, month, year };

        // Handling earliestMonthsCanPlan
        let earlyIndex = this.$store.state.earliestMonthsCanPlan.findIndex(
          (x) => x.segment == segment && x.type == type
        );
        if (earlyIndex === -1) {
          this.$store.state.earliestMonthsCanPlan.push(newPlan);
        } else if (month < this.$store.state.earliestMonthsCanPlan[earlyIndex].month) {
          this.$store.state.earliestMonthsCanPlan[earlyIndex].month = month;
        }

        // Handling latestMonthsCanPlan
        let lateIndex = this.$store.state.latestMonthsCanPlan.findIndex((x) => x.segment == segment && x.type == type);
        if (lateIndex === -1) {
          this.$store.state.latestMonthsCanPlan.push(newPlan);
        } else if (month > this.$store.state.latestMonthsCanPlan[lateIndex].month) {
          this.$store.state.latestMonthsCanPlan[lateIndex].month = month;
        }

        // Update totals and populate data
        this.materialDataTotals[type].total += weight;
        this.populateMaterialDataTotalsBySegment(type, segment, family, weight);
      });
    },
    populateMaterialDataTotalsBySegment(type, segment, family, weight) {
      segment = segment.toLowerCase();
      family = family.toLowerCase();

      // Initialize segment object if it does not exist
      if (!this.materialDataTotals[type][segment]) {
        this.materialDataTotals[type][segment] = { total: 0 };
      }

      // Initialize family property if it does not exist
      if (!this.materialDataTotals[type][segment][family]) {
        this.materialDataTotals[type][segment][family] = 0;
      }

      this.materialDataTotals[type][segment].total += weight;
      this.materialDataTotals[type][segment][family] += weight;
    },

    adjustMaterialWeightsByFamily(data) {
      this.materialWeights = [];
      data.forEach(({ material, segment, family, type, weight }) => {
        let obj = {
          materialID: material,
          units: weight,
          familyWeight: 0,
          segmentWeight: 0,
        };
        const segmentKey = segment.toLowerCase();
        const familyKey = family.toLowerCase();
        obj.segmentWeight = this.materialDataTotals[type][segmentKey].total;
        obj.familyWeight = this.materialDataTotals[type][segmentKey][familyKey];

        this.materialWeights.push(obj);
      });
    },

    calculateMaterialsWeights(data) {
      data.forEach(({ material, type, family, segment }, index) => {
        const weight = this.materialWeights.find((o) => o.materialID === material);
        if (weight) {
          let multiplier = 1;
          const matunit = weight.units < 0 ? 0 : weight.units;

          let ratio = matunit / weight.segmentWeight;
          if (this.product.families[type] !== undefined && this.product.families[type].isActive) {
            ratio = matunit / weight.familyWeight;
            multiplier = this.familyWeightsPercentile(type, family.toLowerCase(), segment) / 100;
          }

          if (isNaN(ratio)) {
            data[index].weight = 0;
          } else {
            data[index].weight = ratio * multiplier;
          }
        }
      });

      return data;
    },
    familyWeightsPercentile(type, family, segment) {
      const tmpSegments = this.product.families[type][segment.toLowerCase()];
      const isOnlyFamily = tmpSegments.length === 1 ? true : false;
      const isFamilySelected =
        this.familiesWeight[type][family] > 0 && this.familiesWeight[type][family] < 100 ? true : false;

      if (isOnlyFamily && family === tmpSegments[0] && isFamilySelected) {
        return 100;
      }
      return this.familiesWeight[type][family];
    },

    populateWeights(data) {
      this.weights = {
        launch: {},
        migrating: {},
      };

      // GET TOTAL WEIGHTS AND MATERIALS BY SEGMENT
      data.forEach(({ type, segment, weight }) => {
        // If the segment does not exist in the given type, initialize it
        if (!this.weights[type][segment]) {
          this.weights[type][segment] = { weight: 0, repeate: 0, delta: 0 };
        }

        this.weights[type][segment].weight += weight;
        this.weights[type][segment].repeate += 1;
      });

      // ADD DELTAS
      for (const segments of Object.values(this.weights)) {
        for (const obj of Object.values(segments)) {
          if (obj.weight <= 0.98) {
            obj.delta = (1 - obj.weight) / obj.repeate;
          }
        }
      }

      data = data.map((obj) => {
        if (this.weights[obj.type][obj.segment].delta !== null && this.weights[obj.type][obj.segment].delta > 0) {
          obj.weight += this.weights[obj.type][obj.segment].delta;
        }
        return obj;
      });
    },
    canPlanForMonth(monthValue, type, segment) {
      let month = this.months.find((x) => x.value == monthValue);
      let foundEarliest = this.$store.state.earliestMonthsCanPlan.find((x) => x.type == type && x.segment == segment);
      if (!foundEarliest || month.value < foundEarliest.month) {
        return false;
      } else {
        return true;
      }
    },

    //need to review for refactor...should get rid of this
    addMaterialTotals(materials) {
      this.localAllData = [];
      this.resetTotals();

      materials.forEach((material) => {
        let obj = {
          type: material.type,
          family: material.family,
          ratio: material.weight,
          amount: material.amount,
          material: material.material,
          weight: material.weight,
          data: [],
          total_units: null,
          description: material.description,
          segment: material.segment,
          gender: material.gender,
          monthly_totals: null,
          material_total: 0,
          shelfdate: material.shelfdate,
        };

        this.localAllData.push(obj);
      });
      this.$store.commit("setMaterialData", this.localAllData);
    },

    async loadCustomAllocations() {
      try {
        this.$materialAllocation.isLoading = true;
        const response = await API.graphql({
          query: getCustomMaterialAllocations,
          variables: {
            soldto_id: this.planningConfig.retailer.soldto_id,
            category: this.planningConfig.product.value,
            year: this.season.year,
          },
        });
        if (
          response.data.getCustomMaterialAllocations != null &&
          response.data.getCustomMaterialAllocations.length > 0
        ) {
          response.data.getCustomMaterialAllocations.forEach((item) => {
            if (item.active != 0) {
              this.$materialAllocation.setPlannedMaterialValueByTypeAndSegment(
                item.type,
                item.segment,
                item.material_number,
                item.month_index,
                item.units_entered
              );
              const bookingTotal = this.$materialAllocation.plannedUnitsByMonthForSegment(
                item.type,
                item.segment,
                item.month_index
              );
              let booking = { month: item.month_index, segment: item.segment, type: item.type, units: bookingTotal };
              let suggestedBooking = { ...booking };
              this.$store.commit("setSuggestedBooking", suggestedBooking);
            }
          });
        } else {
          const reqDelDateStart = (this.bookingYear - 1).toString() + "-03" + "-31";
          const reqDelDateEnd = this.bookingYear.toString() + "-04" + "-01";
          const season = this.planningConfig.product.value.split("-")[0];
          const response = await API.graphql({
            query: getPreviousSellInData,
            variables: {
              soldto_id: this.planningConfig.retailer.soldto_id,
              category: this.planningConfig.product.value,
              req_delivery_dte_start: reqDelDateStart,
              req_delivery_dte_end: reqDelDateEnd,
              previousYear: this.bookingYear - 1,
              season: season,
            },
          });
          response.data.getPreviousSellInData.forEach((item) => {
            this.$events.emit("updateSuggestedBookings", item);
          });
        }
      } catch (e) {
        console.error(e);
      }
      this.$materialAllocation.isLoading = false;
    },
  },
  mounted() {
    this.getAllReferenceUpcs();
    const thisInstance = this;
    this.$root.$on("updatedConfig", function () {
      thisInstance.fetchMaterials();
    });
  },
};
</script>
<style>
.alignAndBorder {
  border: 1px solid #dadada;
  text-align: center !important;
}

.alignAndBorderThick {
  border: 1px solid #dadada;
  text-align: center !important;
}

h5 {
  margin-top: 0px !important;
}

.editableCell {
  background-color: aquamarine;
}

.w-100 {
  min-width: 21px;
}
</style>
